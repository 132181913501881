import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { capitalizeFirstLetter } from '../../../Utils/stringUtils';
import './dropdown.scss';

const Dropdown = ({
    defaultValue,
    options,
    isDisabled,
    isClearable,
    isLoading,
    isSearchable,
    name,
    isRtl,
    value,
    onChange,
    label,
    placeholder,
    isMulti,
    preselected,
    ...props
}) => {
    const [selectedOptions, setSelectedOptions] = useState(preselected);

    useEffect(() => {

        if (defaultValue && isMulti) {
            const defaultOptions = options.filter(option => defaultValue.includes(option.value));
            setSelectedOptions(defaultOptions);
            if (onChange) {
                onChange(defaultOptions);
            }
        }

    }, [defaultValue, options, isMulti, onChange]);
    useEffect(() => {
        if (defaultValue && !isMulti) {
            const foundOption = options.find(option => option.value == defaultValue);

            if (foundOption) {
                setSelectedOptions(foundOption);
            }
        }
    }, []);
    const handleChange = (selectedOption) => {
        setSelectedOptions(selectedOption);
        if (onChange) {
            onChange(selectedOption);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? null : provided.boxShadow,
            borderColor: state.isFocused ? '#FF5E15' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? '#FF5E15' : provided.borderColor,
            }
        })
    };

    const getOptionLabel = (option) => {
        return capitalizeFirstLetter(option.label);
    };
    console.log("placeholder", placeholder)
    return (
        <>
            {label && <Form.Label aria-label={label}>{label}</Form.Label>}
            <Select
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedOptions}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                menuPosition='fixed'
                isRtl={isRtl}
                isSearchable={isSearchable}
                name={name}
                options={options}
                getOptionLabel={getOptionLabel}
                onChange={handleChange}
                placeholder={placeholder || `Select ${label ? label.toLowerCase() : 'User'}`}
                isMulti={isMulti}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--primary)',
                        primary25: '#d2a09e73',
                    },
                })}
                styles={customStyles}
                {...props}
            />
        </>
    );
};

export default Dropdown;

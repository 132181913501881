import React, { useState, useMemo } from 'react'
import { Badge, Card, Col, Row, Form } from 'react-bootstrap'
import IconResource from '../../Wrapper/IconResource/IconResource'
import BasicModal from '../../Components/_widgets/Modal/BasicModal';
import Button from "../../Components/_widgets/Button/Button"
import Login from '../../Components/Login/Login';
import { Link } from 'react-router-dom';
import noPrdct from '../../Assets/img/noproduct.png'
import { formattedValue } from '../../Services/common';
import { changeDateFormatForDisplay, changeDateFormatForServer, momentToDate } from '../../Utils/dateUtils';
import { PRODUCT_DETAIL } from '../../Constants/urlConstants';
import Text from '../../Components/_widgets/Text/Text';
import CustomTooltip from '../../Components/_widgets/Tooltip/CustomTooltip';
import './Products.scss'
import { getPiecePrePrice } from '../../Utils/priceUtils';
import { FREE, ORDER_DETAIL } from '../../Constants/stringConstants';
import { maskText } from '../../Helpers';
const ProductCard = ({ product, userData, handleDelectItem, handleAddItem, getProductQuentity, addProduct, productQty, buttonLoading, addToWishList, isWishList, setIsWishList, deleteToWishlist, defaultAddress, summary }) => {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const handleClose = () => {
        setShowAuthModal(false);
    };

    return (
        <>
            <div style={{ zIndex: 999 }}>
                <BasicModal
                    size='xl'
                    show={showAuthModal}
                    minH={"60vh"}
                    handleClose={handleClose}
                    headerPadding="0.5rem"
                >
                    <Login setShowAuthModal={setShowAuthModal} />
                </BasicModal>
                <Card className="mb-3 border-0" style={{ minHeight: "250px" }}>
                    <Card.Body className="d-flex card-p position-relative shadow rounded-4">
                        {
                            <Row className="w-100">
                                <Col md={4}>
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`/${PRODUCT_DETAIL}/${product?.slug}`}
                                    >
                                        <div>
                                            {
                                                product?.discount_percentage > 0 ?
                                                    <div className="discount-div mb-2">
                                                        <div className="text-white clip-circle">
                                                            <p style={{ wordBreak: "break-all" }}>
                                                                {formattedValue(Math.round(product?.discount_percentage), false, false)}% off
                                                            </p>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div style={{ minHeight: "123px" }} className="product-img position-relative">
                                                <img
                                                    src={product?.first_full_url_media ? product?.first_full_url_media : noPrdct}
                                                    alt={product?.name}
                                                    className="w-100 h-auto object-fit-contain"
                                                    style={{ minWidth: "100px" }}
                                                    onError={(event) => {
                                                        const target = event.target;
                                                        target.src = noPrdct;
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </Link>


                                </Col>
                                <Col md={8}>
                                    <div className='text-start'>
                                        <Link to={`/${PRODUCT_DETAIL}/${product?.slug}`}>
                                            <h5 style={{ minHeight: "39px" }} className="product-title">{product.name}</h5>
                                        </Link>
                                        <div className="d-flex align-items-center">
                                            {
                                                userData?.token && product?.discounted_price ? <small style={{ color: "var(--primary)" }} className="fw-bold">

                                                    {formattedValue(product?.discounted_price, true, true)}
                                                </small> :
                                                    <small style={{ color: "var(--primary)" }} className="fw-bold">
                                                        {maskText(product?.discounted_price, product?.discounted_price?.length)}

                                                    </small>
                                            }
                                            {
                                                product?.mrp > 0 ? <>
                                                    <s style={{ fontSize: "13px", color: "gray" }} className=" mrp mx-2">MRP: {formattedValue(product?.mrp, true, true)}</s>
                                                    <IconResource type="help" title="All price are inclusive of taxes." /></> :
                                                    <>                                                  <Badge pill bg='light' style={{ fontSize: "20px" }} className="text-success my-2 me-2 ">
                                                        {FREE}
                                                    </Badge></>

                                            }

                                        </div>
                                        {product?.slab && <div className="text-success">
                                            Slab prices available
                                        </div>}
                                        {
                                            userData?.token && product?.discount_percentage ?
                                                <Badge pill bg='light' style={{ fontSize: "10px" }} className="text-success my-2 me-2 ">
                                                    Discount: {formattedValue(product.discount_price, true, true)} | {((product?.discount_percentage))}% Off
                                                </Badge> :
                                                product?.discount_percentage &&
                                                <Badge pill bg='light' style={{ fontSize: "10px" }} className="text-success my-2 me-2 ">
                                                    Discount:    {maskText(product?.discount_percentage, product?.discount_percentage?.length)} Off
                                                </Badge>
                                        }
                                        {
                                            userData?.token ?
                                                <>
                                                    {
                                                        summary !== ORDER_DETAIL ?
                                                            <div className='d-flex gap-2 align-items-center mt-2'>
                                                                <div className="purcaase-icons ">
                                                                    <Button
                                                                        disabled={product?.quentity === 0 ? true : false}
                                                                        onClick={() => handleDelectItem((productQty?.find((i) => i.id === product.id)))}
                                                                        icon={<IconResource type="minus" />}
                                                                        buttonType="icon" />
                                                                    <Form.Control className="text-center" type="text" value={getProductQuentity(product)?.qty} readOnly />
                                                                    <Button
                                                                        onClick={() => handleAddItem(productQty?.find((i) => i.id === product.id))}
                                                                        icon={<IconResource type="plus" />}

                                                                        buttonType="icon" />

                                                                </div>
                                                                <Button
                                                                    isLoading={buttonLoading[product?.id] || false}
                                                                    className={"btn emr-btn__primary rounded-pill"}
                                                                    onClick={() => addProduct(getProductQuentity(product))}
                                                                    style={{ fontSize: "11px", padding: "8px", minHeight: "26px", height: "30px", minWidth: "75px" }} text="Add To Cart" buttonType="primary" />
                                                            </div> :
                                                            <div className='d-flex gap-2 align-items-center mt-2'>
                                                                <div className="purcaase-icons ">

                                                                    <Form.Control className="text-center" type="text" value={(product)?.qty} readOnly />

                                                                </div>

                                                            </div>
                                                    }
                                                </> :
                                                <Button
                                                    onClick={() => {
                                                        setShowAuthModal(true);
                                                    }}
                                                    className={"btn emr-btn__primary rounded-pill"}
                                                    text=" Login to Buy"
                                                    style={{ fontSize: "11px", padding: "8px", minHeight: "26px", height: "30px", minWidth: "75px" }} buttonType="primary" />



                                        }

                                    </div>
                                </Col>


                            </Row>
                        }
                        {
                            product?.offers_count > 0 ?
                                <small className='side-ribbon' style={{ fontSize: "12px" }}>{product?.offers_count} offers available</small> : null
                        }



                        {
                            summary !== ORDER_DETAIL ? <>
                                {
                                    isWishList?.[product?.id] === true ? (
                                        <span
                                            className="add_to_wishlist"
                                            style={{ position: "absolute", top: 5, right: 10 }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                deleteToWishlist(product, e);
                                            }}
                                        >
                                            <IconResource
                                                size={20}
                                                type="fill-heart"
                                                color="red"
                                            />
                                        </span>
                                    ) : (
                                        <span
                                            className="add_to_wishlist"
                                            style={{ position: "absolute", top: 5, right: 10 }}

                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                addToWishList(product, e);
                                            }}
                                        >
                                            <IconResource
                                                size={20}
                                                type="heart"
                                                color="black"
                                            />
                                        </span>
                                    )

                                }
                            </> : null
                        }



                        {
                            summary === ORDER_DETAIL ?
                                <div style={{ position: "absolute", top: 5, right: 10, cursor: "pointer" }}>
                                    <a href={`https://wa.me/${9340939715}?text=Hello, I found an amazing deal on LotsHub app!%20*${product?.name}*.%20I think you will love shopping on LotsHub and saving money. For daily deals, download now!%20https://lots.appringer.co.in/${PRODUCT_DETAIL}/${product?.slug}`} target="_blank">
                                        <IconResource type="whatsapp" color="green" />
                                    </a>
                                    {/* <IconResource type="whatsapp" color="green" /> */}
                                </div>
                                :
                                <div style={{ position: "absolute", top: 30, right: 10, cursor: "pointer" }}>
                                    <a href={`https://wa.me/${9340939715}?text=Hello, I found an amazing deal on LotsHub app!%20*${product?.name}*.%20I think you will love shopping on LotsHub and saving money. For daily deals, download now!%20https://lots.appringer.co.in/${PRODUCT_DETAIL}/${product?.slug}`} target="_blank">
                                        <IconResource type="whatsapp" color="green" />
                                    </a>
                                    {/* <IconResource type="whatsapp" color="green" /> */}
                                </div>

                        }
                        <div style={{ position: "absolute", bottom: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "end", gap: 18, width: "100%", padding: "0.5rem 1rem" }}>

                            {
                                product?.expiry_date && <CustomTooltip text={"Expiry Date"}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "var(--secondary)", fontWeight: 500 }}>
                                        <div className="icon-exp">
                                            <IconResource color="var(--secondary)" size={18} type="calendar" />
                                        </div>
                                        <span style={{ fontSize: "10px" }} className='bg-white text-black mt-1'>{changeDateFormatForDisplay(product?.expiry_date)}</span>
                                    </div>
                                </CustomTooltip>
                            }


                            {
                                product?.piece_per_box && <CustomTooltip text="Units per Box">
                                    <div style={{ fontSize: "10px", fontWeight: 500 }} className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className='icon-exp'>
                                            <IconResource color="var(--secondary)" size={13} type="box" />
                                        </div>
                                        <Text
                                            type="h7"
                                            className="mt-1"
                                            text={`${product?.piece_per_box} Units/box`}
                                        />
                                    </div>
                                </CustomTooltip>
                            }
                            {
                                product?.discounted_price && product?.piece_per_box &&
                                <CustomTooltip text="Price Per Unit">
                                    <div style={{ fontSize: "10px", fontWeight: 500 }} className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className="icon-exp">
                                            <IconResource color="var(--secondary)" size={15} type="rupee" />
                                        </div>
                                        <Text
                                            type="h7"
                                            className="mt-1"
                                            text={`${formattedValue(getPiecePrePrice(product?.discounted_price), true, true)}/Unit`}
                                        />
                                    </div>
                                </CustomTooltip>
                            }




                        </div>

                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default ProductCard
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Badge, Breadcrumb, Table } from 'react-bootstrap';
import './Cart.scss';
import ModalConfirmation from '../../Components/_widgets/Modal/ModalConfirmation';
import { Link } from 'react-router-dom';
import Button from '../../Components/_widgets/Button/Button';
import IconResource from '../../Wrapper/IconResource/IconResource';
import Percent from '../../Assets/svgs/Percent';
import Input from '../../Components/_widgets/Input/Input';
import { formattedValue } from '../../Services/common';
import noimg from '../../Assets/img/noproduct.png'
import { slice } from '../../Utils/stringUtils';
import { PRODUCT_DETAIL } from '../../Constants/urlConstants';
import { changeDateFormatForDisplay, changeDateFormatForServer } from '../../Utils/dateUtils';
import CustomTooltip from '../../Components/_widgets/Tooltip/CustomTooltip';
import Text from '../../Components/_widgets/Text/Text';
import { getPiecePrePrice } from '../../Utils/priceUtils';
import { FREE } from '../../Constants/stringConstants';
const CartUi = ({ cartItems, showModal, setShowModal, confirmCheckout, setSelectDefaultAddress, getData, setSelectedAddress, selectedAddress, getAddressData, setAddressData, address, selectDefaultAddress, confirmDefaultAddress, handleDelectItem, handleAddItem, getProductQuentity, addProduct, getProductPrice, productQty, setProductQty, coupon, setCoupon, applyCoupon, addToWishList, emptyCart, removeToWishList, getDefaultAddress, buttonLoading, handleClose, addressLoading, handleClosePayment }) => {


    console.log("cartItems", cartItems)
    return (
        <Container className="cart">
            <ModalConfirmation
                show={showModal}
                heading={selectDefaultAddress ? `Select Delivery Address` : `Delivery/Pickup Confirmation`}
                footer={false}
                button1Text={selectDefaultAddress ? "Confirm" : "ConfirmToCheckOut"}
                button1Click={selectDefaultAddress ? confirmCheckout : confirmDefaultAddress}
                button2Text={"Cancel"}
                handleClose={() => selectDefaultAddress ? handleClosePayment() : handleClose()}
                address={address}
                size='md'
                setAddressData={setAddressData}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                addressLoading={addressLoading}

            />
            <Breadcrumb style={{ fontSize: "var(--p0)", fontWeight: "600" }}>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Cart</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col md={8} className=' bg-white rounded-2  p-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h4 className="text-start"> My Cart <span className="text-muted h6"><span className='fw-bold'>{cartItems?.items?.length} items </span>in Cart</span></h4>
                        <div className="text-end">
                            <Link to="/cart" className="text-danger fw-bold small" onClick={() => emptyCart()}>
                                Clear cart
                            </Link>
                        </div>
                    </div>
                    <div className="delivery-address my-2 mb-3 border text-start py-3 px-3 d-flex justify-content-between align-items-center ">
                        <h6 className='d-flex align-items-center'>
                            <div className="d-flex mr-2">
                                <IconResource color="var(--primary)" type="delivery" />
                            </div>
                            <small className="d-flex mx-2">
                                Delivering to &nbsp; <strong> {getDefaultAddress(address) || ''} </strong></small></h6>
                        <Link style={{ color: "var(--primary)" }} className=" ms-2 fw-bold" onClick={(e) => {
                            setSelectDefaultAddress(false)
                            setShowModal(true)
                        }} to="/cart">Change</Link>

                    </div>
                    <div >

                        <div>
                            {cartItems?.items?.map(item => (
                                <div className='d-flex align-items-center gap-2 mb-3 p-2' style={{ border: "1px solid var(--border-light)" }} key={item?.products?.id}>
                                    <div>
                                        <div className='d-flex align-items-center gap-3 ' style={{ minWidth: "300px" }}>
                                            <div style={{ maxWidth: "90px", minWidth: "90px", background: "gray", minHeight: "90px", maxHeight: "100px", overflow: "hidden" }}>
                                                <Link className='h-100 w-100' to={`/${PRODUCT_DETAIL}/${item?.products?.slug}`}>
                                                    <img src={item?.products?.first_full_url_media ? item?.products?.first_full_url_media : noimg} alt={item.products?.name} className="p-img w-100 h-100 " />
                                                </Link>
                                            </div>
                                            <div className='text-start'>
                                                <Link to={`/${PRODUCT_DETAIL}/${item?.products?.slug}`}>
                                                    <h6 className='lh-custom fw-bold mb-2'>{slice(item?.products?.name, 20)}</h6>
                                                </Link>
                                                <div className="d-flex align-items-center">
                                                    {
                                                        item?.status === 0 && <>
                                                            <small style={{ color: "var(--primary)" }} className="fw-bold">
                                                                {formattedValue(item?.products?.discounted_price, true, true)}
                                                            </small>
                                                            <s style={{ fontSize: "13px", color: "gray" }} className=" mrp mx-2">MRP: {formattedValue(item?.products?.mrp, true, true)}</s>
                                                            <IconResource type="help" title="All price are inclusive of taxes." /></>
                                                    }


                                                </div>
                                                {item?.products?.slab && <div className="text-success">
                                                    Slab prices available
                                                </div>}
                                                {
                                                    item?.status === 0 && <Badge pill bg='light' style={{ fontSize: "10px" }} className="text-success my-2 me-2 ">
                                                        Discount: {formattedValue(item?.products?.discount_price, true, true)} | {((item?.products?.discount_percentage))}% Off
                                                    </Badge>
                                                }
                                                {/* <div className='text-start'>

                                                    <Link to="/cart" style={{ fontSize: "12px" }} className="text-danger fw-bold " onClick={() => removeToWishList(item)}>
                                                        Remove&nbsp;
                                                    </Link>
                                                    <Link to="/cart" style={{ fontSize: "12px" }} className="fw-bold " onClick={() => addToWishList(item)}>
                                                        |&nbsp;Move to Wishlist
                                                    </Link>

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end", gap: 18, padding: "0.5rem 1rem" }}>
                                        {
                                            item?.products?.expiry_date &&
                                            <CustomTooltip text={"Expiry Date"}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "var(--secondary)", fontWeight: 500 }}>
                                                    <div className="icon-exp">
                                                        <IconResource color="var(--secondary)" size={18} type="calendar" />
                                                    </div>
                                                    <span style={{ fontSize: "10px" }} className='bg-white text-black mt-1'>{changeDateFormatForDisplay(item?.products?.expiry_date) || ""}</span>
                                                </div>
                                            </CustomTooltip>

                                        }
                                        {
                                            item?.products?.piece_per_box && item?.status === 0 &&

                                            <CustomTooltip text="Units per Box">
                                                <div style={{ fontSize: "10px", fontWeight: 500 }} className='d-flex flex-column justify-content-center align-items-center'>
                                                    <div className='icon-exp'>
                                                        <IconResource color="var(--secondary)" size={13} type="box" />
                                                    </div>
                                                    <Text
                                                        type="h7"
                                                        className="mt-1"
                                                        text={`${item?.products?.piece_per_box} Units/box`}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        }


                                        {
                                            item?.status === 0 && <CustomTooltip text="Price Per Unit">
                                                <div style={{ fontSize: "10px", fontWeight: 500 }} className='d-flex flex-column justify-content-center align-items-center'>
                                                    <div className="icon-exp">
                                                        <IconResource color="var(--secondary)" size={15} type="rupee" />
                                                    </div>
                                                    <Text
                                                        type="h7"
                                                        className="mt-1"
                                                        text={`${formattedValue(getPiecePrePrice(item?.products?.discounted_price), true, true)}/Unit`}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        }


                                    </div>


                                    {
                                        item?.status === 0 ? <>
                                            <div>
                                                <div className="purcaase-icons">
                                                    <Button
                                                        disabled={item?.products?.quentity === 0 ? true : false}
                                                        onClick={() => handleDelectItem((productQty?.find((i) => i.id === item?.products.id)))}
                                                        icon={<IconResource type="minus" />}
                                                        buttonType="icon" />
                                                    <Form.Control className="text-center" type="text" value={getProductQuentity(item?.products)?.value} readOnly />
                                                    <Button
                                                        onClick={() => handleAddItem(productQty?.find((i) => i.id === item?.products.id))}
                                                        icon={<IconResource type="plus" />}

                                                        buttonType="icon" />

                                                </div>
                                                <Button
                                                    isLoading={buttonLoading[item?.products?.id] || false}
                                                    className={"btn emr-btn__primary rounded-pill w-100 mt-2"}
                                                    onClick={() => addProduct(getProductQuentity(item?.products))}
                                                    style={{ fontSize: "11px", padding: "8px", minHeight: "26px", height: "30px", minWidth: "75px" }} text="Update Price" buttonType="primary" />
                                            </div>



                                        </> :
                                            <>

                                                <div className="purcaase-icons">
                                                    <Form.Control className="text-center" type="text" value={item?.qty} readOnly />




                                                </div>





                                            </>
                                    }
                                    {
                                        item?.status === 0 ? <div className="text-end ms-5">
                                            <h5 className='fw-bold'>{formattedValue(getProductPrice(item), true)}</h5>
                                        </div> :
                                            <Badge pill bg='light' style={{ fontSize: "20px" }} className="text-success my-2 me-2 ">
                                                {FREE}
                                            </Badge>
                                    }

                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
                {
                    cartItems?.items?.length > 0 ?
                        <Col md={4} >
                            <div className="summary-box p-3 bg-white rounded-2 border">
                                <div className='d-flex justify-content-between pb-2'>
                                    <h6 className='small'>Order Items</h6>
                                    <h6 className='fw-bold small'>{cartItems?.order_items} Items</h6>
                                </div>
                                <div className="d-flex justify-content-between pb-2">
                                    <h6 className='small'>Total MRP</h6>
                                    <h6 className='small fw-bold'> {formattedValue(cartItems?.total_mrp, true)}</h6>
                                </div>
                                <div className="d-flex justify-content-between pb-2">
                                    <h6 className='small'>Discount Savings</h6>
                                    <h6 className='small fw-bold'> - {formattedValue(cartItems?.margin_savings, true)} </h6>
                                </div>
                                <div className="d-flex justify-content-between pb-2">
                                    <h6 className='small'>Subtotal</h6>
                                    <h6 className='small fw-bold'> {formattedValue(cartItems?.subtotal, true)}</h6>
                                </div>

                                <div className="d-flex justify-content-between pb-2 mt-2">
                                    <h5 className=''>Total</h5>
                                    <div>
                                        <h4 className='fw-bold'>
                                            {formattedValue(cartItems?.total, true)}*</h4>
                                        <p>*excl. of delivery fee</p>
                                    </div>
                                </div>

                                <p className='saving text-success fw-bold py-1 mt-2'>You have saved {formattedValue(cartItems?.margin_savings, true)} on this order!</p>

                                <Button

                                    buttonType="fullwidth"
                                    text={"Proceed to Checkout"}
                                    onClick={() => {
                                        setShowModal(true)
                                        setSelectDefaultAddress(true)
                                    }} ></Button>

                            </div>
                        </Col> : ""
                }

            </Row>
        </Container>
    );
};

export default CartUi;
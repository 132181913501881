import { ADDCART, USERLOGOUT } from "./../../Constants/actionTypeConst";
export const addItems = (payload) => ({
    type: ADDCART,
    payload: payload
})


export const clearData = (payload) => ({
    type: USERLOGOUT,

})
import React from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import './steps.scss';

const step1Content = <div>Order Created</div>;
const step2Content = <div>Order Confirmed</div>;
const step3Content = <div>In Process</div>;
const step4Content = <div>Invoiced</div>;
const step5Content = <div>Out For Delivery</div>;
const step6Content = <div>Delivered</div>;

const CustomStepProgress = ({ step }) => {
    return (
        <div className="custom-step-progress">
            <StepProgressBar
                startingStep={step}
                buttonWrapperClass='step-buttons'
                labelClass='step-label'
                stepClass='step'
                progressClass="progress-line"
                steps={[
                    {
                        name: 'step1',
                        label: step1Content,
                    },
                    {
                        name: 'step2',
                        label: step2Content,
                    },
                    {
                        name: 'step3',
                        label: step3Content,
                    },
                    {
                        name: 'step4',
                        label: step4Content,
                    },
                    {
                        name: 'step5',
                        label: step5Content,
                    },
                    {
                        name: 'step6',
                        label: step6Content,
                    },
                ]}
            />
        </div>
    );
};

export default CustomStepProgress;
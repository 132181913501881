import React from 'react'

const Returns = () => {
    return (
        <>
            <div class="text-start mt-5" pageid="returns">
                <input type="hidden" id="deferredLoadStatic" value="false" />
                <section class="section-overview mb-3 mb-md-5">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card p-3 p-md-5">
                                    <h2><u>Return and Exchange Policy</u></h2>
                                    <div class="container1">
                                        <div class="controlcontent_r">
                                            <div class="htmlbucket">
                                                <p>&nbsp;</p>
                                                <div class="contentdiv">
                                                    <p><strong>I. RETURN AND EXCHANGE</strong></p>
                                                    <p>This Policy allows for the return and exchange of all merchandise, including the private brands products (except electronics, general merchandise and other exceptions as listed in point II below) within a period of 15 (fifteen) working days from the date of purchase as stated on the invoice subject to fulfillment of the conditions prescribed in this Policy.</p>
                                                    <p><strong>II. ELECTRONICS AND GENERAL MERCHANDISE</strong></p>
                                                    <p>This Policy allows for the return and exchange of most of electronics and general merchandise, including the private brands products within a period of 5 (five) working days from the date of purchase as stated on the invoice subject to fulfillment of the conditions prescribed in this Policy. After 5 (five) working days from the date of invoice, the manufacturer’s warranty procedure (if applicable) shall be applicable on such merchandise.</p>
                                                    <p><strong>III. Exceptions</strong></p>
                                                    <p>This Policy is not applicable on the purchase of the following merchandise:</p>
                                                    <table border="1" cellspacing="0" cellpadding="0">
                                                        <thead>
                                                            <tr>
                                                                <td width="50%">
                                                                    <p>Non-Food Items</p>
                                                                </td>
                                                                <td width="50%">
                                                                    <p>Food Items</p>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top">
                                                                    <ul>
                                                                        <li>Undergarments and Personal Apparel</li>
                                                                        <li>Towels &amp; Bedsheets</li>
                                                                        <li>Jewelry</li>
                                                                        <li>Baby Care Products / Fem Hygiene</li>
                                                                        <li>OTC drug items</li>
                                                                        <li>Personal Care Products</li>
                                                                        <li>Music/Audio Players</li>
                                                                        <li>Laptop / Desktop /Computer Components and Accessories</li>
                                                                        <li>Tobacco and Tobacco Products</li>
                                                                        <li>Any product that is highlighted as “non-returnable” on the product detail page on the Company’s ecommerce website</li>
                                                                    </ul>
                                                                </td>
                                                                <td valign="top">
                                                                    <ul>
                                                                        <li>Fresh Chilled Meat, Fish and Chicken</li>
                                                                        <li>Fresh Loose Vegetables and Fruits</li>
                                                                        <li>Loose Staples</li>
                                                                        <li>Baby Food Products</li>
                                                                        <li>Cold Chain Dairy / Frozen Products</li>
                                                                        <li>Fresh Bakery Products /Cut fruits</li>
                                                                        <li>Any product that is highlighted as “non-returnable” on the product detail page on the Company’s ecommerce website</li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p><strong>IV. Other terms and conditions:</strong></p>
                                                    <ol start="1" type="1">
                                                        <li>Wal-Mart India Private Limited (“ <strong>Company</strong>”) reserves the right to inspect the merchandise and verify the nature and extent of damage prior to processing refunds or exchange under this Policy. The decision of the Company shall be final and binding on the member. The Company shall not entertain any claim or dispute by member in this regard.</li>
                                                        <li>This Policy shall not be applicable where the merchandise is due for expiry within the next 15 (fifteen) working days.</li>
                                                        <li>Original Invoice must be presented by the member as a proof of purchase at the time of seeking any return or exchange of the merchandise.</li>
                                                        <li>Return will be processed only if:</li>
                                                    </ol>
                                                    <ol start="1" type="1">
                                                        <ul>
                                                            <li>it is determined that the product was not damaged while in the member’s possession; due to accident, negligence, misuse or for any other reason;</li>
                                                            <li>the product is not different from what was purchased by the member or shipped to member;</li>
                                                            <li>the product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and all the accessories and freebies (if any) therein) provided with the product;</li>
                                                        </ul>
                                                    </ol>
                                                    <ol start="5" type="1">
                                                        <li>All refund amounts shall be returned via the same mode in which the Company had received the payment during the purchase by the member.</li>
                                                        <li>All approved refunds shall be returned to member within a maximum period of 7 (seven) working days.</li>
                                                        <li>This Policy is not applicable for return and exchange of merchandise covered under discount / special price or any other promotion or schemes,</li>
                                                    </ol>
                                                    <ol start="8" type="1">
                                                        <li>This Policy has been rolled out at the sole discretion of the Company. The Company reserves the right to modify / withdraw this Policy without notice at any time. The decision of the Company in relation to any matter arising out of this Policy shall be final and binding on the member.</li>
                                                        <li>In case the merchandise is not available for exchange, the purchase price shall be refunded to the member.</li>
                                                        <li>For any quality, safety &amp; shortage related complaints, you are requested to log in your complaint with complete details including invoice at <a href="mailto:customer.care@walmart.com">customer.care@walmart.com</a>. Our customer care executives will reach out to you for the resolution of your concern as soon as possible.</li>
                                                        <li>For further information or any other queries, please feel free to contact the Best Price Flipkart Wholesale Stores. For any query or feedback, please call on 01204878888 or write to us on <a href="mailto:membersupport@walmart.com">membersupport@walmart.com</a></li>
                                                        <li>The courts at Delhi shall have the exclusive jurisdiction in relation to any matter arising out of this Policy.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div class="r_bottom">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Returns
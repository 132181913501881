import { memo } from "react";
import "./login.scss";
import Text from "../_widgets/Text/Text";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import IconResource from "../../Wrapper/IconResource/IconResource";
import One from "../../Assets/img/1.png";
import Two from "../../Assets/img/2.png";
import Three from "../../Assets/img/3.png";
import Four from "../../Assets/img/4.png";
import Five from "../../Assets/img/5.png";
import Button from "../_widgets/Button/Button";
import Input from "../_widgets/Input/Input";
import { maskText } from "../../Helpers";
import { preventPlusMinusInNumberInput } from "../../Services/common";
import MainLoader from "../_widgets/Loader/MainLoader";
import logo from '../../Assets/img/logo.png'

const LoginUi = memo(({ loading, navigate, initialLoginData, loginData, setLoginData, validateUser, handleLogin, handleSendOtp, handleForgotPassword, resendOTPTimer, handleVerifyOTP, validateUserForRegister, handleUpdatePassword }) => {
  console.log("loginData", loginData)

  return (
    <div className="bg_primary">
      <Row className="d-flex mx-0 flex-column-reverse flex-md-row">
        <Col lg={12} xl={6} className="bg-white rounded_right">
          <div className="d-flex align-items-center justify-content-between h-100 position-relative">
            <div className="login_form m-auto">
              <img src={logo} alt="logo" />
              <Text
                className="login_header"
                type={"h1"}
                text={(loginData.pageAction === 'login' ? 'Welcome' : "Register") + " to LotsHub"}
              />
              <Text
                className="login_sub_heading mb-3"
                type={"small"}
                text="Enter your details to proceed further"
              />

              {loginData.pageAction === 'login'
                ? !loginData.isUserVerified && !loginData.isOtpSent
                  ? <Input
                    label={
                      <>
                        Membership ID/Phone No. <span style={{ color: 'red' }}>*</span>
                      </>
                    }

                    placeholder="Enter Membership ID/Phone Number"
                    type="number"

                    min={10}
                    max={10}
                    value={loginData.mno}
                    onKeyDown={e => preventPlusMinusInNumberInput(e)}
                    onChange={e => setLoginData({ ...loginData, mno: e.target.value?.slice(0, 10) })}
                  />
                  : loginData.isUserVerified && !loginData.isOtpSent
                    ? <div>
                      <Text text={maskText(loginData.mno, 4)} />

                      <div className="mt-3">
                        <Input
                          inputType="password"
                          label={
                            <>
                              Password <span style={{ color: 'red' }}>*</span>
                            </>
                          }

                          value={loginData.password}
                          onChange={e => setLoginData({ ...loginData, password: e.target.value.slice(0, 30) })}
                        />
                      </div>
                    </div>
                    : null
                : !loginData.isUserVerified && !loginData.isOtpSent
                  ?

                  <Row>
                    <Col lg={6}>
                      <Input
                        label={
                          <>
                            Full Name <span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        placeholder="Enter your Name"
                        type="text"
                        value={loginData.name}
                        onKeyDown={e => preventPlusMinusInNumberInput(e)}
                        onChange={e => setLoginData({ ...loginData, name: e.target.value?.slice(0, 50) })}
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        label={
                          <>
                            Phone No. <span style={{ color: 'red' }}>*</span>
                          </>
                        }

                        placeholder="Enter Phone Number"
                        type="number"
                        min={10}
                        max={10}
                        value={loginData.mno}
                        onKeyDown={e => preventPlusMinusInNumberInput(e)}
                        onChange={e => setLoginData({ ...loginData, mno: e.target.value?.slice(0, 10) })}
                      />
                    </Col>
                    <Col lg={6}>
                      <Form.Label aria-label="Email Adress">
                        Email Address (Optional)
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter your Email"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          type="email"
                          autoComplete="off"
                          name="disable-autofill-email"
                          id="disable-autofill-email"
                          value={loginData.email}
                          onChange={e => setLoginData({ ...loginData, email: e.target.value?.slice(0, 50) })}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <IconResource type="envelop" size={16} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col lg={6}>
                      <Input
                        inputType="password"
                        label={
                          <>
                            Password <span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        value={loginData.password}
                        onChange={e => setLoginData({ ...loginData, password: e.target.value.slice(0, 30) })}
                      />
                    </Col>
                  </Row>
                  : null}

              {loginData.isUserVerified && loginData.isOtpSent
                && <div>
                  {
                    loginData?.isForget === true ?
                      <>
                        <Row>
                          <Col lg={6}>

                            <Input
                              inputType="text"
                              label={
                                <>
                                  Mobile Number <span style={{ color: 'red' }}>*</span>
                                </>
                              }

                              value={maskText(loginData.mno, 4)}

                            />
                          </Col>
                          <Col lg={6}>
                            <Input
                              inputType="text"
                              label={
                                <>
                                  OTP <span style={{ color: 'red' }}>*</span>
                                </>
                              }
                              value={loginData.otp}
                              onChange={e => setLoginData({ ...loginData, otp: e.target.value.slice(0, 30) })}
                            />

                          </Col>

                          <Col lg={6}>
                            <Input
                              inputType="password"
                              label={
                                <>
                                  Password <span style={{ color: 'red' }}>*</span>
                                </>
                              }
                              value={loginData.password}
                              onChange={e => setLoginData({ ...loginData, password: e.target.value.slice(0, 30) })}
                            />

                          </Col>
                          <Col lg={6}>
                            <Input
                              inputType="password"
                              label={
                                <>
                                  New Password  <span style={{ color: 'red' }}>*</span>
                                </>
                              }
                              value={loginData.confirmPassowrd}
                              onChange={e => setLoginData({ ...loginData, confirmPassowrd: e.target.value.slice(0, 30) })}
                            />

                          </Col>
                        </Row>
                      </> :
                      <>
                        <Text text={`Enter Four Digit OTP sent to ${maskText(loginData.mno, 4)}`} className='mb-3' />

                        <Input
                          label={
                            <>
                              OTP <span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          placeholder="Enter OTP"
                          type="number"
                          min={4}
                          max={4}
                          value={loginData.otp || ''}
                          onKeyDown={e => preventPlusMinusInNumberInput(e)}
                          onChange={(e) => setLoginData({ ...loginData, otp: e.target.value?.slice(0, 4) })}
                        /></>
                  }
                </div>}

              {loginData.isUserVerified &&
                (loading === "forgetPassword" ? <div className="relative"><MainLoader /></div> : <Text text={!loginData.isOtpSent ? 'Forgot Password?' : 'Wrong Number? Click to Update'} style={{ color: "var(--primary)", cursor: "pointer" }}
                  onClick={!loginData.isOtpSent
                    ? handleForgotPassword
                    : () => setLoginData({ ...initialLoginData, email: loginData.email, mno: loginData.mno, name: loginData.name })} />)}

              {loginData.isUserVerified && loginData.isOtpSent
                && <Text
                  text={loading === 'sendOtp' ? 'Resending...' : `Resend OTP ${resendOTPTimer ? 'in ' + (resendOTPTimer / 1000) + ' sec' : ""}`}
                  style={{ color: resendOTPTimer ? "var(--light-text)" : "var(--primary)", cursor: resendOTPTimer ? "text" : "pointer" }}
                  onClick={!resendOTPTimer ? handleSendOtp : null}
                />}

              <div className="mt-4 d-flex flex-column gap-2">
                <Button
                  buttonType={"primary"}
                  onClick={loginData.pageAction === 'login'
                    ? !loginData.isUserVerified && !loginData.isOtpSent
                      ? validateUser
                      : loginData.isUserVerified && !loginData.isOtpSent
                        ? handleLogin
                        : loginData.isUserVerified && loginData.isOtpSent && loginData?.isForget === false
                          ? handleVerifyOTP
                          : handleUpdatePassword
                    : loginData.pageAction === 'register'
                      ? !loginData.isUserVerified && !loginData.isOtpSent
                        ? validateUserForRegister
                        : loginData.isUserVerified && loginData.isOtpSent
                          ? handleVerifyOTP
                          : null
                      : null}
                  text={loginData.isUserVerified
                    ? loginData.pageAction === 'login' && loginData.isForget === false
                      ? "Log In"
                      : loginData.isForget === true ? "Update Password" : "Register"
                    : "Continue"}
                  isLoading={loading === 'login' || loading === 'checkUser' || loading === 'verifyOTP'}
                />

                {loginData.isUserVerified && !loginData.isOtpSent &&
                  <Button
                    buttonType={"primary"}
                    onClick={handleSendOtp}
                    text={"Login via OTP"}
                    isLoading={loading === 'sendOtp'}
                  />}
              </div>

              {!loginData.isUserVerified &&
                <div className="mt-3 d-flex gap-1">
                  <Text text={loginData.pageAction === 'login' ? 'New to LotsHub?' : 'Already a Member?'} />
                  <Text
                    text={loginData.pageAction === 'login' ? 'Apply for Membership' : 'Login'}
                    style={{ color: "var(--primary)", cursor: 'pointer' }}
                    onClick={() => {
                      navigate(loginData.pageAction === 'login' ? '/?action=register' : '/?action=login');
                      setLoginData(prev => ({ ...prev, pageAction: prev.pageAction === 'login' ? 'register' : 'login' }));
                    }} />
                </div>}
            </div>
          </div>
        </Col>

        <Col lg={0} xl={6}>
          <div className="w-100 h-100 d-none d-lg-flex align-items-center justify-content-center flex-column px-4">
            <div className="d-flex align-items-center gap-4 w-100 py-3 " style={{ borderBottom: "1px solid #f1f1f1" }}>
              <img width={80} height={65} src={One} alt="" />
              <p className="fs-6 text-white">Best Wholesale Prices Every Day</p>
            </div>
            <div className="d-flex align-items-center gap-4 w-100 py-3 " style={{ borderBottom: "1px solid #f1f1f1" }}>
              <img width={80} height={65} src={Two} alt="" />
              <p className="fs-6 text-white">Wide Range Of Products</p>
            </div>
            <div className="d-flex align-items-center gap-4 w-100 py-3 " style={{ borderBottom: "1px solid #f1f1f1" }}>
              <img width={80} height={65} src={Three} alt="" />
              <p className="fs-6 text-white">Doorstep Delivery</p>
            </div>
            <div className="d-flex align-items-center gap-4 w-100 py-3 " style={{ borderBottom: "1px solid #f1f1f1" }}>
              <img width={80} height={65} src={Four} alt="" />
              <p className="fs-6 text-white">Easy Credit</p>
            </div>
            <div className="d-flex align-items-center gap-4 w-100 py-3 " style={{ borderBottom: "1px solid #f1f1f1" }}>
              <img width={80} height={65} src={Five} alt="" />
              <p className="fs-6 text-white">Quick Refunds</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default LoginUi;

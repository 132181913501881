export const MASTER_DATA = "master_data";
export const SYSTEM_DATA = "system_data";
export const GUEST_USER = "guest";
export const USER_DATA = "user_data";
export const AUTH = 'auth';
export const PROFILE = 'profile';
export const USER_ROLE = 'user_role'
export const RUPEE = "₹";
export const ITEM_CAROUSEL_3 = "3_ITEM_CAROUSEL";
export const CAROUSEL = "CAROUSEL";
export const BANNER = "BANNER";
export const CARD_6 = "6_CARD";
export const PRODUCT = "product";
export const ITEM_CAROUSEL_6 = "6_ITEM_CAROUSEL";
import PaymentsUi from './PaymentsUi';
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addItems, clearData } from '../../Redux/Action/ProductAction';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessPopUp from '../../Components/_widgets/Modal/SuccessPopUp';

const Payments = () => {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [allCoupon, setAllCoupon] = useState([])
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false)
  const [isPlaced, setIsPlaced] = useState(false)
  const [appliedCouponData, setAppliedCouponData] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)
  const [fillCoupon, setFillCoupon] = useState({
    label: '',
    value: '',
    discount: ''

  });
  const [selectCoupon, setSelectCoupon] = useState({
    label: '',
    value: '',
    discount: ''

  });
  const [clearCoupon, setClearCoupon] = useState(false)
  const [couponLoading, setCouponLoading] = useState(false)
  const locationData = useLocation();
  const selectAddress = locationData.state;
  useEffect(() => {
    getData()
  }, [])
  const applyCouponSelection = async (coupon) => {
    setCouponLoading(true)
    if (coupon.value) {
      let payload = {
        amount: data?.total
      }
      var response = await apiHelper.applyCoupon(payload, coupon?.value,)
      if (response?.isSuccess) {
        toast.success(response?.message)
        setCouponLoading(false)
        setAppliedCouponData(response?.data)
      }
      else {
        setAppliedCouponData("")
        setCouponLoading(false)
        toast.warn(response?.message)

      }
    }
    else {
      toast.warn("Please enter coupon code")
    }
  }
  const applyCoupon = async () => {

    setButtonLoading(true)
    let payload = {
      amount: data?.total
    }
    var response = await apiHelper.applyCoupon(payload, fillCoupon?.value,)
    if (response?.isSuccess) {
      toast.success(response?.message)
      setButtonLoading(false)
      setAppliedCouponData(response?.data)
    }
    else {
      toast.warn(response?.message)
      setAppliedCouponData("")
      setButtonLoading(false)
    }
  }
  const convertCoupons = (coupons) => {
    return coupons?.map(coupon => {
      // Map to format desired label and value
      return {
        label: `${coupon.code} - ${coupon?.discount}`,  // Assign coupon id to generate the label
        value: coupon.code,
        discount: coupon.discount
        // Use coupon id as value
      };
    });
  }
  const getCoupon = async () => {


    var response = await apiHelper.getCoupon()

    if (response?.isSuccess) {
      let allcoupon = convertCoupons(response?.data)
      setAllCoupon(allcoupon)
      setClearCoupon(false)
      toast.success(response.success)

    }
    else {
      toast.warn(response?.message)

    }
  }
  const placedOrder = async (payload) => {

    setLoading(true)
    let addIds = []

    payload.products.map((i) => {
      addIds.push(i.product_id)
    })
    let data = {
      products: addIds,
      coupon_id: appliedCouponData?.id,
      user_address_id: selectAddress?.id,
      pay_mode: "CASH"

    }
    var response = await apiHelper.placeOrder(data)

    if (response?.isSuccess) {
      setShow(true)
      setIsPlaced(true)
      var cartItem = await apiHelper.getCartItem()
      if (cartItem?.isSuccess) {
        dispatch(addItems(cartItem?.data))
      }
      dispatch(clearData([]))
      setLoading(false)

    }
    else {
      getData()
      setShow(false)
      setLoading(false)
      toast.warn(response?.message)
    }
  }

  const getData = async () => {
    var response = await apiHelper.getCheckOut()
    if (response?.isSuccess) {
      setData(response?.data)

    }
    else {
      toast.warn(response?.message)
    }

  }
  const handleClose = () => {
    setShow(false)
    window.scrollTo(0, 0)
    navigate("/")

  }
  const myOrder = () => {
    setShow(false);
    navigate("/order")
  }

  return (
    <><PaymentsUi
      applyCoupon={applyCoupon}

      placedOrder={placedOrder}
      data={data}
      selectAddress={selectAddress}
      getCoupon={getCoupon}
      allCoupon={allCoupon}
      appliedCouponData={appliedCouponData}
      couponLoading={couponLoading}
      buttonLoading={buttonLoading}
      applyCouponSelection={applyCouponSelection}
      setFillCoupon={setFillCoupon}
      setSelectCoupon={setSelectCoupon}
      fillCoupon={fillCoupon}
      selectCoupon={selectCoupon}
      clearCoupon={clearCoupon}
      setClearCoupon={setClearCoupon}
      setAppliedCouponData={setAppliedCouponData}
    />

      <SuccessPopUp
        isPlaced={isPlaced}
        loading={loading}
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        myOrder={myOrder}
      />

    </>
  )
}

export default Payments
import './App.css';
import { useEffect, lazy, Suspense, useState, memo } from 'react';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import * as commonServices from './Services/common';
import * as storageConstants from './Constants/storageConstant';
import { getProfile } from './Services/ApiHelper';
import { warn } from './Wrapper/toast/toast';

import ProtectedRoute from './Protected/Protected';
import Cart from './Pages/Cart/Cart';
import Tnc from './Pages/Tnc/Tnc';
import Privacy from './Pages/Privacy/Privacy';
import Returns from './Pages/Returns/Returns';
import Payments from './Pages/Payments/Payments';
import FullScreenLoader from './Components/_widgets/Loader/FullScreenLoader';
import CommingSoon from './Components/CommingSoon/CommingSoon';
import OrderDetailUi from './Components/Order/OrderDetailsUi';



const MainLayout = lazy(() => import('./Components/Layouts/MainLayout'));
const Brands = lazy(() => import('./Pages/Brands/Brands'));
const HomeLayout = lazy(() => import('./Components/Layouts/HomeLayout'));
const MyAccountLayout = lazy(() => import('./Components/Layouts/MyAccountLayout'));
const MyAccount = lazy(() => import('./Pages/MyAccount/MyAccount'));
const Wishlist = lazy(() => import('./Components/Wishlist/Wishlist'));
const Order = lazy(() => import("./Components/Order/Order"));
const ChangePassword = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const HomePage = lazy(() => import('./Components/HomePage/HomePage'));
const Offers = lazy(() => import('./Pages/Offers/Offers'));
const Products = lazy(() => import('./Pages/Products/Products'));
const NotFound = lazy(() => import('./Components/NotFound/NotFound'));
const ProductDescription = lazy(() => import("./Pages/Products/ProductDescription"));
const Checkout = lazy(() => import("./Pages/Checkout/Checkout"));

const App = memo(() => {

  const [loading, setLoading] = useState({ main: false, profile: false });

  useEffect(() => {
    commonServices.fetchMasterData(state => setLoading({ ...loading, main: state }));
    window.scrollTo(0, 0)
    //   commonServices.getLocalData(storageConstants.USER_DATA)?.token && getProfileData();
  }, []);

  const getProfileData = async () => {
    setLoading({ ...loading, profile: true });
    const resp = await getProfile();
    setLoading({ ...loading, profile: false });
    if (resp?.isSuccess) {
      commonServices.storeLocalData(storageConstants.PROFILE, resp?.data);
    } else {
      warn(resp?.message);
    }
  }

  return (
    <div className="App">
      <ToastContainer />

      <Suspense fallback={<FullScreenLoader />}>
        {loading.main || loading.profile
          ? <FullScreenLoader />
          : <Routes>
            <Route element={<MainLayout />}>
              <Route exact path='/' element={<ProtectedRoute Component={<HomePage />} />} />
              <Route exact path="/offers" element={<Offers />} />
              <Route exact path="/cart" element={<Cart />} />
              <Route exact path="/brands/:id" element={<Brands />} />
              <Route exact path="/product/:slug" element={<ProductDescription />} />
              <Route exact path="/returns" element={<Returns />} />
              <Route exact path="/payments" element={<Payments />} />
              <Route exact path="/tnc" element={<Tnc />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route exact path="/checkout" element={<Checkout />} />
              <Route exact path="/comming-soon" element={<CommingSoon />} />
              <Route exact path="/order-details" element={<OrderDetailUi />} />
              <Route element={<HomeLayout />}>
                <Route exact path="/products" element={<Products />} />

              </Route>




              <Route element={<MyAccountLayout />}>
                <Route exact path="/my-account" element={<MyAccount />} />
                <Route exact path="/wishlist" element={<Wishlist />} />
                <Route exact path="/order" element={<Order />} />
                <Route exact path="/change-password" element={<ChangePassword />} />
              </Route>
              <Route exact path="/*" element={<NotFound />} />
            </Route>
          </Routes>}
      </Suspense>
    </div>
  );
});

export default App;

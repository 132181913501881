import { memo } from 'react';
import LottieAnimation from '../_widgets/Lottie';
import animation from '../_widgets/Animations/NoData.json'
import Button from '../_widgets/Button/Button';

const NoData = memo(({ heading, buttonClick }) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <p style={{ fontSize: "20px", margin: "10px 0px", fontWeight: 600 }}>
                    {heading || "No Order Yet"}
                </p>
                <div className='mt-3 mb-4'>
                    <LottieAnimation
                        animation={animation}
                        width={296}
                        height={218}
                        loop
                    />
                </div>

                <Button
                    onClick={buttonClick}
                    text="Continue Shopping" buttonType="outline" />
            </div>
        </>
    );
});

export default NoData;

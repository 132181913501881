import "../Login/login.scss";
import { memo } from "react";
import animation from '../_widgets/Animations/Comming.json'
import LottieAnimation from "../_widgets/Lottie";

const CommingSoon = memo(() => {

    return (
        <div className="vh100">
            <div className="h-100 d-flex align-items-center justify-content-around">
                <LottieAnimation
                    animation={animation}
                    loop
                    width={700}
                    height={600}
                />
            </div>
        </div>
    );
});

export default CommingSoon;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LottieAnimation from '../Lottie';
import animation from "../Animations/OrderPlaced.json";
import MainLoader from '../Loader/MainLoader';
import Button from '../Button/Button';
function SuccessPopUp({ setShow, show, handleClose, loading, isPlaced, myOrder, homePage }) {


    return (
        <div>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Order {!loading ? "Placed Successfully" : "Processing..."}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading ?
                        <>
                            <LottieAnimation
                                animation={animation}
                                loop
                                height={250}
                            />
                            <div className='d-flex gap-2 w-100 justify-content-center'>
                                <Button
                                    buttonType="primary"
                                    text={"My Order"}
                                    onClick={(e) => myOrder()}
                                />
                                {/* <Button
                                    buttonType="primary"
                                    text={"Home Page"}
                                    onClick={(e) => handleClose()}
                                /> */}
                            </div>

                        </> : <div className='position-relative'><MainLoader /></div>}

                </Modal.Body>
            </Modal>
        </div>
    );
}

export default SuccessPopUp;

import React from 'react'

const Tnc = () => {
    return (
        <>
            <div className="text-start mt-5">
                <h2 className='mb-2' ><strong>Terms of Use</strong></h2>
                <p ><span >This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder, as applicable, and the amended provisions pertaining to electronic records in various statutes, as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span></p>
                <p>This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and terms of use for access or usage of domain name&nbsp;<a href="https://www.bestprice.in/%C2%A0">https://www.bestprice.in/&nbsp;</a>(“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”).</p>
                <p>The Platform is owned by&nbsp;WAL-MART INDIA PVT LTD&nbsp;a company incorporated under the Companies Act, 1956 with its registered office at E-20, 1st &amp; 2nd Floor, Hauz Khas Main Market, New Delhi- 110016 (hereinafter referred to as " Best Price Flipkart Wholesale").</p>
                <p>Your use of the Platform, services and tools is governed by the following terms and conditions&nbsp;("Terms of Use"), including the applicable policies, rules and guidelines, which are incorporated herein by way of reference. By mere use of the Platform, You shall be contracting with Best Price Flipkart Wholesale and this Terms of Use, including the associated policies, rules, and guidelines constitute Your binding obligations with Best Price Flipkart Wholesale.</p>
                <p>For the purpose of these Terms of Use, wherever the context so requires,&nbsp;"You"&nbsp;or&nbsp;"User"&nbsp;or “Buyer” shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing registration data while registering on the Platform as registered user using the computer systems. Best Price Flipkart Wholesale allows the User to surf the Platform without registering on the Platform. The term&nbsp;"We", "Us", "Our"&nbsp;shall mean Best Price Flipkart Wholesale.</p>
                <p>We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review this Terms of Use periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with this Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.</p>
                <p>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THIS TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting this Terms of Use, You also accept and agree to be bound by Best Price Flipkart Wholesale policies (including but not limited to Privacy Policy) as amended from time to time.</p>
                <p><strong>Membership</strong>&nbsp;<strong>Eligibility</strong></p>
                <p>You acknowledge that You are required to complete the onboarding process and furnish a valid business license as a condition precedent to use the Platform. All fields identified as mandatory must be duly populated by You as part of the onboarding process. You will not be eligible to be on-boarded on the Platform as a Buyer if You provide incomplete or inaccurate information and/or fail to provide a valid business license (from the list of acceptable business licenses as listed on the Platform). Best Price Flipkart Wholesale reserves the right to evaluate (i) the information; and (ii) the business license; provided by You to confirm its authenticity, post which You will be permitted to use the Platform. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or Best Price Flipkart Wholesale has at any time, reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, Best Price Flipkart Wholesale shall have the right to indefinitely suspend or terminate or block access of Your membership on the Platform and refuse to provide You with access to the Platform.</p>
                <p>Best Price Flipkart Wholesale unequivocally clarifies that You will be permitted to use the Platform and buy products from sellers on the Platform (“Seller<strong>”</strong>), (i) only on an wholesale basis; and (ii) only if You qualify as a “business customer” as per the “Guidelines for Cash &amp; Carry Wholesale Trading” under the Consolidated FDI Policy 2017 (“FDI Policy”). You agree that any products purchased by You on the Platform will soley be for the purpose of resale, trade, business, institutional and/or industrial use and not for personal consumption. Your business address shall be the default shipping address on the invoice generated pursuant to a purchase transaction on the Platform.</p>
                <p>Best Price Flipkart Wholesale reserves the right to collect all details from a “business customer” as required under the FDI Policy including but not limited to (i) the name of business entity, (ii) nature of business entity, (iii) license/registration number of valid business license provided; (iv) volume of sale, etc. Best Price Flipkart Wholesale shall rely on the copy of the business licenses/documents provided by You. You undertake that the business licenses / documents submitted are genuine and valid. Your registration of the Platform shall remain valid till the period the business license provided by You remains valid. In case there is no expiry date on the valid business license, the relevant business license shall be considered valid for a maximum of 99 years from the date of Your enrolment. You agree to notify Best Price Flipkart Wholesale immediately if any information furnished by You, as part of the on-boarding process, changes, including but not limited to, change in business address, contact number, change of authorized representatives, suspension/ cancellation of the business license, discontinuation of business, etc.</p>
                <p>Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If You are a minor i.e. under the age of 18 years, You may access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</p>
                <p><strong>Your Account and Registration Obligations</strong></p>
                <p>If You use the Platform, You shall be responsible for maintaining the confidentiality of Your Display Name and Password and You shall be responsible for all activities that occur under Your Display Name and Password.</p>
                <p>Your mobile phone number is treated as Your primary identifier on the Platform. It is Your responsibility to ensure that Your mobile phone number is up to date on the Platform at all times. You agree to notify Us promptly if Your mobile phone number changes by updating the same on the Platform through a onetime password verification.</p>
                <p>You agree that Best Price Flipkart Wholesale shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under Your account on the Platform (“Account”) in any case, including, where You have failed to update Your revised mobile phone number on the Platform.</p>
                <p>If You share or allow others to have access to Your Account on the Platform by creating separate profiles under Your Account, or otherwise, they will be able to view and access Your Account information. You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences therefrom.</p>
                <p>Best Price Flipkart Wholesale can, at any time, terminate Your account or place Your account on hold in order to protect our or the interests of the Content Owners or any individual/entity associated with the Platform, from an actual, probable or suspected theft, loss, damage or other fraudulent activity. Best Price Flipkart Wholesale reserves the right to immediately terminate, suspend, limit, or restrict Your access or use of the Platform and/or the services, at any time, without any notice or liability, if Best Price Flipkart Wholesale so determines, in its sole discretion, for any reason whatsoever, including that You have: (a) breached these Terms of Use or the Privacy Policy; or (b) violated any applicable law, rule, or regulation; or (c) You have engaged in any inappropriate or unlawful conduct; or (d) provided any false or inaccurate information; or (e) for any other reason that Best Price Flipkart Wholesale deems fit in its sole discretion.</p>
                <p><strong>Communications</strong></p>
                <p>When You use the Platform or send emails or other data, information or communication to Us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us, periodically, and as and when required. We may communicate with You by email or by any other mode of communication, electronic or otherwise.</p>
                <p><strong>Platform for Transaction and Communication</strong></p>
                <p>The Platform is a platform that Users utilize to meet and interact with one another for their transactions. Best Price Flipkart Wholesale is not and cannot be a party to or control in any manner any transaction between the Platform's Users.</p>
                <p>Henceforward:</p>
                <ul>
                    <li>All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation, price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services, and after sales services related to products and services. Best Price Flipkart Wholesale does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers. All discounts, offers (including exchange offers) are by the Seller/Brand and not by Best Price Flipkart Wholesale.</li>
                    <li>Placement of order by a Buyer with Seller on the Platform is an offer to buy the product(s) in the order by the Buyer to the Seller and it shall not be construed as Seller's acceptance of Buyer's offer to buy the product(s) ordered. The Seller retains the right to cancel any such order placed by the Buyer, at its sole discretion and the Buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid by Buyer in case of such cancellation by Seller, shall be refunded to the Buyer.</li>
                    <li>Best Price Flipkart Wholesale does not make any representation or warranty as to specifics (such as quality, value, salability, etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform. Best Price Flipkart Wholesale does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. Best Price Flipkart Wholesale accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</li>
                    <li>Best Price Flipkart Wholesale is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. Best Price Flipkart Wholesale cannot and does not guarantee that the concerned Buyers and/or Sellers will perform any transaction concluded on the Platform. Best Price Flipkart Wholesale shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.</li>
                    <li>Best Price Flipkart Wholesale does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that regard.</li>
                    <li>Best Price Flipkart Wholesale does not, at any point of time during any transaction between Buyer and Seller on the Platform, come into or gain title of any of the products or services offered by Seller nor does it at any point gain title to or have any rights or claims over the products or services offered by Seller to Buyer.</li>
                    <li>At no time shall Best Price Flipkart Wholesale hold any right, title or interest over the products nor shall Best Price Flipkart Wholesale have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers. Best Price Flipkart Wholesale is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</li>
                    <li>The Platform is only a platform that can be utilized by Users to reach a larger base to buy and sell products or services. Best Price Flipkart Wholesale is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer.</li>
                    <li>You shall independently agree upon the manner and terms and conditions of delivery, payment, insurance etc. with the Seller(s) that You transact with.</li>
                </ul>
                <p>Disclaimer: Pricing on any product(s) as is reflected on the Platform may, due to some technical issue, typographical error, or product information published by Seller, be incorrectly reflected and in such an event Seller may cancel such order(s).</p>
                <ul>
                    <li>You release and indemnify Best Price Flipkart Wholesale and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that You may have in this behalf under any applicable law. Notwithstanding its reasonable efforts in that regard, Best Price Flipkart Wholesale cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.</li>
                </ul>
                <p>Please note that there could be risks in dealing with underage persons or people acting under false pretense.</p>
                <p><strong>Charges</strong></p>
                <p>Membership on the Platform is free for the Buyers. Best Price Flipkart Wholesale does not charge any fee for browsing and buying on the Platform. Best Price Flipkart Wholesale reserves the right to change its Fee Policy from time to time. In particular, Best Price Flipkart Wholesale may at its sole discretion introduce new services and modify some or all of the existing services offered on the Platform. In such an event, Best Price Flipkart Wholesale reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be posted on the Platform and such changes shall automatically become effective immediately after they are posted on the Platform. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws including those in India for making payments to Best Price Flipkart Wholesale.</p>
                <p><strong>Use of the Platform</strong></p>
                <p>You agree, undertake and confirm that Your use of Platform shall be strictly governed by the following binding principles:</p>
                <ul>
                    <li>You shall not host, display, upload, modify, publish, transmit, update or share any information which:</li>
                </ul>
                <p>(a) belongs to another person and to which You do not have any right to;</p>
                <p>(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
                <p>(c) is misleading in any way;</p>
                <p>(d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
                <p>(e) harasses or advocates harassment of another person;</p>
                <p>(f) involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</p>
                <p>(g) promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</p>
                <p>(h) infringes upon or violates any third party's rights&nbsp;[including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</p>
                <p>(i) promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</p>
                <p>(j) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</p>
                <p>(k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</p>
                <p>(l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</p>
                <p>(m) contains video, photographs, or images of another person (with a minor or an adult).</p>
                <p>(n) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</p>
                <p>(o) engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, Best Price Flipkart Wholesale's prior written consent means a communication coming from Best Price Flipkart Wholesale's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</p>
                <p>(p) solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</p>
                <p>(q) interferes with another User's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</p>
                <p>(r) refers to any Platform or URL that, in Our sole discretion, contains material that is inappropriate for the Platform or any other Platform, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</p>
                <p>(s) harm minors in any way;</p>
                <p>(t) infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</p>
                <p>(u) violates any law for the time being in force;</p>
                <p>(v) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
                <p>(w) impersonates another person;</p>
                <p>(x) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</p>
                <p>(y) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
                <p>(z) shall not be false, inaccurate or misleading;</p>
                <p>(aa) shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</p>
                <p>(ab) shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers;</p>
                <p>(ac) contravenes Competition Act 2002 (e.g. transmitting any non-public commercially sensitive information to a competitor or any other person, directly or indirectly). Non-public commercially sensitive information would mean information which is not in public domain and which can influence a commercial decision or strategy of a competitor or third party such as cost structure, future prices, plans, sales volumes, production capacity, distribution network, supplier/customer information.</p>
                <ul>
                    <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve Our right to bar any such activity.</li>
                    <li>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.</li>
                    <li>You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other customer, including any account on the Platform not owned by You, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Platform.</li>
                    <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms Flipkart, Flipcart, Best Price Flipkart Wholesale , flipkartwholesale.com, or otherwise engage in any conduct or action that might tarnish the image or reputation, of Best Price Flipkart Wholesale or Sellers on platform or otherwise tarnish or dilute any Best Price Flipkart Wholesale's trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or Best Price Flipkart Wholesale's systems or networks, or any systems or networks connected to Best Price Flipkart Wholesale.</li>
                    <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.</li>
                    <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmission You send to Us on or through the Platform or any service offered on or through the Platform. You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity.</li>
                    <li>You may not use the Platform or any content for any purpose that is unlawful or prohibited by this Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Best Price Flipkart Wholesale and / or others.</li>
                    <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations (including, but not limited to GST/Sales Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty, Local Levies) regarding Your use of Our service and Your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>
                    <li>Solely to enable Us to use the information You supply Us with, so that We are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your information, in any media now known or not currently known, with respect to Your Information. We will only use Your information in accordance with this Terms of Use and Our Privacy Policy.</li>
                    <li>You shall not engage in advertising to, or solicitation of, other Users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which a User may send to other Users in any 24-hour period which We deem appropriate in Our sole discretion. You understand that We have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as We, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
                </ul>
                <p>We reserve the right, but have no obligation, to monitor the materials posted on the Platform. Best Price Flipkart Wholesale shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of this Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect Best Price Flipkart Wholesale views. In no event shall Best Price Flipkart Wholesale assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that You have all necessary rights in and to all Content which You provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information.</p>
                <ul>
                    <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between You and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</li>
                    <li>It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Platform and that You may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about You due to Your use of the Platform, and that the recipient may use such information to harass or injure You. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that We are not responsible for the use of any personal information that You publicly disclose or share with others on the Platform. Please carefully select the type of information that You publicly disclose or share with others on the Platform.</li>
                    <li>Best Price Flipkart Wholesale shall have all the rights to take necessary action and claim damages that may occur due to Your involvement/participation in any way on Your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>
                </ul>
                <p><strong>Contents Posted on Platform</strong></p>
                <p>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork (collectively, "Content"), is third party user generated content and Best Price Flipkart Wholesale has no control over such third party user generated content as Best Price Flipkart Wholesale is merely an intermediary for the purposes of this Terms of Use.</p>
                <p>Except as expressly provided in this Terms of Use, no part of the Platform and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, platform or other medium for publication or distribution or for any commercial enterprise, without Best Price Flipkart Wholesale's express prior written consent.</p>
                <p>You may use information on the products and services purposely made available on the Platform for downloading, provided that You (1) do not remove any proprietary notice language in all copies of such documents, (2) use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) do not make any additional representations or warranties relating to such documents.</p>
                <p>You shall be responsible for any notes, messages, emails, reviews, ratings, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the Platform. Such Content will become Our property and You grant Us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include the Content You provide. You agree that any Content You post may be used by us, consistent with Our Privacy Policy and Terms of Use as mentioned herein, and You are not entitled to any payment or other compensation for such use.</p>
                <p>SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THE PLATFORM.</p>
                <p><strong>Privacy</strong></p>
                <p>Best Price Flipkart Wholesale will collect, process and/ or use the information provided by You (including details of transactions on the platform). Best Price Flipkart Wholesale may also share the aforesaid information with its associated companies for administering and performing customer care, suggesting credit &amp; financing product(s)/scheme(s), personalized customer services as well as for sales promotion, marketing and opinion research activities, offering additional services, schemes, promotions, opportunities, enrolment in special programs, etc. You expressly consent to share such information and to receive calls and messages from telemarketers (engaged by Best Price Flipkart Wholesale or its associated companies) for the aforesaid purposes.</p>
                <p>In the event, Best Price Flipkart Wholesale is required by any law, regulation, validly given order of any judicial or government authority or legal process to disclose any details related to You or purchases made on Your behalf, You give unconditional consent to share all such details with the concerned authority without any prior intimation to You.</p>
                <p>Please also review Our Privacy Policy, which governs Your use of Our Platform to understand Our privacy practices in detail. Any personal information provided to Us by You during the course of usage of Platform will be treated in accordance with Our Privacy Policy and applicable laws and regulations. If You object to Your information being collected, used, transferred or otherwise processed by us, please do not use the Platform.</p>
                <p><strong>Disclaimer of Warranties and Liability</strong></p>
                <p>This Platform, all the materials and products (including but not limited to software) and services, included on or otherwise made available to You through this Platform are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Best Price Flipkart Wholesale does not warrant that:</p>
                <ul>
                    <li>This Platform will be constantly available, or available at all; or</li>
                    <li>The information on this Platform is complete, true, accurate or non-misleading.</li>
                </ul>
                <p>Best Price Flipkart Wholesale will not be liable to You in any way or in relation to the Contents of, or use of, or otherwise in connection with, the Platform. Best Price Flipkart Wholesale does not warrant that this site; information, Content, materials, product (including software) or services included on or otherwise made available to You through the Platform; their servers; or electronic communication sent from Us are free of viruses or other harmful components.</p>
                <p>Nothing on the Platform constitutes, or is meant to constitute, advice of any kind. All the Products sold on Platform are governed by different state laws and if Seller is unable to deliver such Products due to implications of different state laws, Seller will return or will give credit for the amount (if any) received in advance by Seller from the sale of such Product that could not be delivered to You.</p>
                <p>You will be required to enter a valid phone number while placing an order on the Platform. By registering Your phone number with us, You consent to be contacted by Us via phone calls, SMS notifications, mobile applications and/or any other electronic mode of communication.</p>
                <p><strong>Services</strong></p>
                <p>Payment</p>
                <p>While availing any of the payment method/s available on the Platform, We will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to:</p>
                <ul>
                    <li>Lack of authorization for any transaction(s), or</li>
                    <li>Exceeding the preset limit mutually agreed by You and between "Bank(s)", or</li>
                    <li>Any payment issues arising out of the transaction, or</li>
                    <li>Decline of transaction for any other reason.</li>
                </ul>
                <p>All payments made against the purchases/services on Platform by You shall be compulsorily in Indian Rupees acceptable in the Republic of India. Platform will not facilitate transaction with respect to any other form of currency with respect to the purchases made on Platform.</p>
                <p>Before shipping / delivering Your order to You, Seller may request You to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by You for Your purchase. This is done in the interest of providing a safe online shopping environment to Our Users.</p>
                <p>Further:</p>
                <ul>
                    <li>Transactions, transaction price and all commercial terms such as Delivery, Dispatch of products and/or services are as per principal to principal bipartite contractual obligations between Buyer and Seller and payment facility is merely used by the Buyer and Seller to facilitate the completion of the transaction. Use of the payment facility shall not render Best Price Flipkart Wholesale liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the products and /or services listed on Best Price Flipkart Wholesale's Platform.</li>
                    <li>You have specifically authorized Best Price Flipkart Wholesale or its service providers to collect, process, facilitate and remit payments and / or the transaction price electronically or through Cash on Delivery to and from other Users in respect of transactions through Payment Facility. Your relationship with Best Price Flipkart Wholesale is on a principal to principal basis and by accepting this Terms of Use, You agree that Best Price Flipkart Wholesale is an independent contractor for all purposes, and does not have control of, or liability for, the products or services that are listed on Best Price Flipkart Wholesale Platform that are paid for by using the Payment Facility. Best Price Flipkart Wholesale does not guarantee the identity of any User nor does it ensure that a Buyer or a Seller will complete a transaction.</li>
                    <li>You understand, accept and agree that the Payment Facility provided by Best Price Flipkart Wholesale is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through Cash On Delivery, collection and remittance facility for the transactions on the Best Price Flipkart Wholesale Platform using the existing authorized banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility, Best Price Flipkart Wholesale is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</li>
                </ul>
                <p>Payment Facility for Buyers:</p>
                <ul>
                    <li>You, as a Buyer, understand that upon initiating a transaction You are entering into a legally binding and enforceable contract with the Seller to purchase the products and /or services from the Seller using the Payment Facility, and You shall pay the transaction price through Your Issuing Bank to the Seller using Payment Facility.</li>
                    <li>You, as a Buyer, may agree with the Seller through electronic communication and electronic records and using the automated features as may be provided by Payment Facility on any extension / increase in the Dispatch and/or Delivery time and the transaction shall stand amended to such extent. Any such extension / increase of Dispatch / Delivery time or subsequent novation / variation of the transaction should be in compliance with Payment Facility Rules and Policies.</li>
                    <li>You, as a Buyer, shall electronically notify Payment Facility using the appropriate Best Price Flipkart Wholesale Platform features immediately upon Delivery or non Delivery within the time period as provided in Policies. Non notification by You of Delivery or non Delivery within the time period specified in the Policies shall be construed as a deemed Delivery in respect of that transaction. In case of Cash On Delivery transactions, Buyer is not required to confirm the receipt of products or services.</li>
                    <li>You, as a Buyer, shall be entitled to claim a refund of the transaction price (as Your sole and exclusive remedy) in case You do not receive the Delivery within the time period agreed in the transaction or within the time period as provided in the Policies, whichever is earlier. In case You do not raise a refund claim using Platform features within the stipulated time than this would make You ineligible for a refund.</li>
                    <li>You, as a Buyer, understand that the Payment Facility may not be available in full or in part for certain category of products and/or services and/or transactions as mentioned in the applicable policies and hence You may not be entitled to a refund in respect of the transactions for those products and /or services.</li>
                    <li>Except for Cash On Delivery transaction, refund, if any, shall be made at the same Issuing Bank from where transaction price was received, or through any other method available on the Platform, as chosen by You.</li>
                    <li>For Cash On Delivery transactions, refunds, if any, will be made via electronic payment transfers or through any other process as communicated to You from time to time.</li>
                    <li>Refund shall be made in Indian Rupees only and shall be equivalent to the transaction price received in Indian Rupees.</li>
                    <li>For electronics payments, refund shall be made through payment facility using NEFT / RTGS or any other online banking / electronic funds transfer system approved by Reserve Bank India (RBI).</li>
                    <li>Refunds may be supported for select banks. Where a bank is not supported for processing refunds, You will be required to share alternate bank account details with us for processing the refund.</li>
                    <li>Refund shall be conditional and shall be with recourse available to Best Price Flipkart Wholesale in case of any misuse by Buyer.</li>
                    <li>We may also request You for additional documents for verification.</li>
                    <li>Refund shall be subject to Buyer complying with all applicable Policies.</li>
                    <li>Best Price Flipkart Wholesale reserves the right to impose limits on the number of transactions or transaction price which Best Price Flipkart Wholesale may receive from an individual Valid Credit/Debit/ Cash Card / Valid Bank Account/ and such other infrastructure or any other financial instrument directly or indirectly through payment aggregator or through any such facility authorized by Reserve Bank of India to provide enabling support facility for collection and remittance of payment or by an individual Buyer during any time period, and reserves the right to refuse to process transactions exceeding such limit.</li>
                    <li>Best Price Flipkart Wholesale reserves the right to refuse to process transactions by Buyers with a prior history of questionable charges including without limitation breach of any agreements by Buyer with Best Price Flipkart Wholesale or breach/violation of any law or any charges imposed by Issuing Bank or breach of any policy.</li>
                    <li>Best Price Flipkart Wholesale may do such checks as it deems fit before approving the receipt of/Buyers commitment to pay (for Cash On Delivery transactions) transaction price, for security or other reasons at the discretion of Best Price Flipkart Wholesale. As a result of such check if Best Price Flipkart Wholesale is not satisfied with the creditability of the Buyer or genuineness of the transaction or other reasons at its sole discretion, Best Price Flipkart Wholesale shall have the right to reject the receipt of / Buyers commitment to pay transaction price. For avoidance of doubt, it is hereby clarified that the ‘Cash on Delivery’ feature for payment, may be disabled for certain account users, at the sole discretion of Best Price Flipkart Wholesale.</li>
                    <li>Best Price Flipkart Wholesale may delay notifying the payment confirmation i.e. informing Seller to Dispatch, if Best Price Flipkart Wholesale deems suspicious, or for Buyers conducting high transaction volumes to ensure safety of the transaction and transaction price. In addition, Best Price Flipkart Wholesale may hold transaction price and Best Price Flipkart Wholesale may not inform Seller to Dispatch or remit transaction price to law enforcement officials (instead of refunding the same to Buyer) at the request of law enforcement officials or in the event the Buyer is engaged in any form of illegal activity.</li>
                    <li>The Buyer and Seller acknowledge that Best Price Flipkart Wholesale will not be liable for any damages, interests or claims etc. resulting from not processing a transaction/transaction price or any delay in processing a transaction/transaction price which is beyond control of Best Price Flipkart Wholesale.</li>
                </ul>
                <p>Compliance with Laws:</p>
                <ul>
                    <li>Buyer and Seller shall comply with all the applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued thereunder and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Competition Law and any policy that may be formulated in this regard, Prevention of Money Laundering Act, 2002 and the rules made thereunder, Foreign Contribution Regulation Act, 1976 and the rules made thereunder, Income Tax Act, 1961 and the rules made thereunder, Export Import Policy of government of India) applicable to them respectively for using Payment Facility and the Platform in general.</li>
                    <li>Buyer and Seller shall strictly comply with all applicable laws and regulations including but not limited to: (a) anti-money laundering (including know your customer and customer due-diligence) and sanctions (economic and trade) enforced by the United Nations, the Republic of India, U.S. Department of Treasury’s Office of Foreign Assets Control (OFAC); and (b) anti-bribery, anti-corruption, including but not limited to the U.S. Foreign Corrupt Practices Act and the UK Bribery Act.</li>
                </ul>
                <p>Buyer's arrangement with Issuing Bank:</p>
                <ul>
                    <li>All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms and conditions agreed to between the Buyer and the respective Issuing Bank and payment instrument issuing company.</li>
                    <li>All Online Bank Transfers from Valid Bank Accounts are processed using the gateway provided by the respective Issuing Bank which support Payment Facility to provide these services to the Users. All such Online Bank Transfers on Payment Facility are also governed by the terms and conditions agreed to between Buyer and the respective Issuing Bank.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Digital Content: Best Price Flipkart Wholesale Ideas</strong></p>
                <ul>
                    <li>These Terms of Service (“Terms”) govern Your access to and use of Best Price Flipkart Wholesale Ideas (hereinafter referred to as “Content”). By viewing the Contents You agree to be bound by these Terms. Content posted on the Platform is public and can be viewed by everyone who can see the Page.</li>
                    <li>Best Price Flipkart Wholesale is hosting the Content generated by third party users (“Content Owner”), within the FK Platform for convenience of Platform Users, and as such, Best Price Flipkart Wholesale has no ownership or control whatsoever in the Contents displayed. The Contents posted by the Content Owners are of personal views wherein Best Price Flipkart Wholesale plays no role in creating/developing the Content. You are responsible for Your use of the Platform for viewing the Content.</li>
                    <li>By hosting the Content on the Platform, Best Price Flipkart Wholesale is neither guaranteeing nor giving any warranty nor making any representation with respect to the Contents. Best Price Flipkart Wholesale is neither endorsing the views, products or the services, nor is it responsible for quality or features of the content displayed on the Platform.</li>
                    <li>Any information on the Platform, whether the Content or any other information, have not been verified by Best Price Flipkart Wholesale, therefore, Best Price Flipkart Wholesale shall not be responsible for any such Content or information. Best Price Flipkart Wholesale will not bear any obligation or liability if You rely on the Content.</li>
                    <li>If You choose to view or rely, Best Price Flipkart Wholesale will neither be a party to nor in any manner concerned with the same nor be liable or responsible for any act or omission of the Content Owner.</li>
                    <li>You hereby acknowledge that the Content is created and owned by Content Owners who own all the rights including the copyrights in the contents. The Contents are available on the Platform for personal use only and You shall not use it for any commercial, promotional, resale or further distribution purposes.</li>
                    <li>Any use or reliance on any Content or materials obtained by You through the Content is at Your own risk. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted on the Platform or endorse any opinions expressed via the contents. You understand that by using the Platform, You may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, postings that have been mislabeled or are otherwise deceptive. All Content is the sole responsibility of the person who originated such Content. Flipkart may not monitor or control the Content posted on the Platform and, We do not take responsibility for such Content.</li>
                    <li>We reserve the right to remove Content that violates the Terms of Use, including for example, copyright or trademark violations, impersonation, unlawful conduct, or harassment.</li>
                    <li>You may find content that offends You or that You simply don’t like, but that doesn’t violate the content guidelines. If that happens, You can unfollow the User who posted it or hide the post.</li>
                    <li>Your access to and use of the services or any Content are at Your own risk. You understand and agree that the services are provided to You on an “AS IS” and “AS AVAILABLE” basis. Best Price Flipkart Wholesale makes no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of any Content; (ii) any harm to Your computer system, loss of data, or other harm that results from Your access to or use of any Content; (iii) the deletion of, or the failure to store or to transmit, any Content and other communications maintained by the services; and (iv) whether the services will meet Your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained through the Content, will create any warranty or representation not expressly made herein.</li>
                    <li>By using the services You agree that Best Price Flipkart Wholesale, its parents, affiliates, related companies, officers, directors, employees, agents representatives, partners and licensors, liability is limited to the maximum extent permissible by law.</li>
                    <li>We may revise these Terms from time to time.</li>
                </ul>
                <p><strong>Indemnity</strong></p>
                <p>You shall indemnify and hold harmless Best Price Flipkart Wholesale, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to, or arising out of, Your breach of this Terms of Use, Privacy Policy and other policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</p>
                <p><strong>Applicable Law</strong></p>
                <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Bangalore.</p>
                <p><strong>Jurisdictional Issues/Sale in India Only</strong></p>
                <p>Unless otherwise specified, the material on the Platform is presented solely for the purpose of sale in India. Best Price Flipkart Wholesale make no representation that materials in the Platform are appropriate or available for use in other locations/Countries other than India. Those who choose to access this site from other locations/countries other than India do so on their own initiative and Best Price Flipkart Wholesale is not responsible for supply of products/refund for the products ordered from other locations/Countries other than India, or compliance with local laws, if and to the extent local laws are applicable.</p>
                <p><strong>Trademark, Copyright and Restriction</strong></p>
                <p>This site is controlled and operated by Best Price Flipkart Wholesale and products are sold by respective Sellers. All material on this site, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights. Material on Platform is solely for Your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which You receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause.</p>
                <p>Best Price Flipkart Wholesale does not warrant that Product description or other content of this Platform is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.</p>
                <p><strong>Limitation of Liability</strong></p>
                <p>IN NO EVENT SHALL BEST PRICE FLIPKART WHOLESALE BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THIS TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES. BEST PRICE FLIPKART WHOLESALE’S TOTAL LIABILITY TO YOU UNDER THIS AGREEMENT SHALL NOT EXCEED TRANSACTION VALUE OF THE RELEVANT TRANSACTION WHICH RESULTS IN THE LIABILITY.</p>
                <p><strong>Other Businesses</strong></p>
                <p>Best Price Flipkart Wholesale does not take responsibility or liability for the actions, products, content and services on the Platform, which are linked to Affiliates and / or third party Platforms using Platform's APIs or otherwise. In addition, the Platform may provide links to the third party Platforms of Our affiliated companies and certain other businesses for which, Best Price Flipkart Wholesale assumes no responsibility for examining or evaluating the products and services offered by them. Best Price Flipkart Wholesale do not warrant the offerings of, any of these businesses or individuals or the content of such third party Platform(s). Best Price Flipkart Wholesale does not endorse, in any way, any third party Platform(s) or content thereof.</p>
                <p><strong>Contact Us</strong></p>
                <p>Customer Support: To get help with any of your queries related to your order or complaints, you can reach our customer support team at 1800 419 9898</p>
                <p><strong>POLICIES</strong></p>
                <p><strong>Profanity Policy</strong></p>
                <p>Best Price Flipkart Wholesale prohibits the use of language that is racist, hateful, sexual or obscene in nature in a public area.</p>
                <p>This policy extends to text within listings, on Seller pages and all other areas of the site that another User may view. If the profane words are part of a title for the item being sold, We allow Sellers to 'blur' out the bulk of the offending word with asterisks (i.e., s<em><strong>&nbsp;or f</strong></em>).</p>
                <p>Please report any violations of this policy to the correct area for review:</p>
                <ul>
                    <li>Report offensive Display Names</li>
                    <li>Report offensive language in a listing or otherwise</li>
                </ul>
                <p>If a feedback comment; or any communication made between Users on the Platform; or email communication between Users in relation to transactions conducted on Platform contain profanity, please review Our feedback removal policy and submit a request for action/removal.</p>
                <p>Disciplinary action may result in the indefinite suspension of a User's account, temporary suspension, or a formal warning.</p>
                <p>Best Price Flipkart Wholesale will consider the circumstances of an alleged policy violation and the user's trading records before taking action.</p>
                <p>Violations of this policy may result in a range of actions, including:</p>
                <ul>
                    <li>Limits placed on account privileges;</li>
                    <li>Loss of special status;</li>
                    <li>Account suspension.</li>
                </ul>
                <p>Best Price Flipkart Wholesale shall have the right to delete a product review posted by You at its sole discretion, if it is of the opinion that the review contains offensive language as stated above. Further, if Best Price Flipkart Wholesale is of the opinion that the review unfairly either: (i) causes disadvantage to a Product; or (ii) increases the popularity of the Product, Best Price Flipkart Wholesale shall have the right to delete the customer review. Best Price Flipkart Wholesale shall also, at its sole discretion have the right to blacklist the customer from posting any further customer reviews.</p>
                <p><u>Returns Policy</u></p>
                <p>Please refer to Our&nbsp;<a href="https://www.bestprice.in/_s/returns">Returns Policy</a>&nbsp;for details related to Product returns and conditions governing returns, exchange or refund of products (as applicable).</p>
                <p><strong>Grievance Resolutions Policy</strong></p>
                <p>Overview</p>
                <p>Generally, transactions are conducted smoothly on the Platform. However there may be some cases where You may face issues related to a transaction which may include:</p>
                <ul>
                    <li>Receipt of Product with Physical Damage</li>
                    <li>Receipt of Product with Manufacturing Defect</li>
                    <li>Receipt of Wrong Product</li>
                    <li>Receipt of Product not matching the description or specifications mentioned on the listing page of the Platform</li>
                    <li>Issues related to the Quality/Size/Colour/Fit delivered</li>
                    <li>Receipt of expired Product</li>
                    <li>Fake product</li>
                    <li>Product no longer wanted</li>
                </ul>
                <p>In such instances You can reach out to Our Grievance Officer and Best Price Flipkart Wholesale will actively work towards reaching a resolution.</p>
                <p>Disputes via Chargeback</p>
                <p>Whenever a chargeback (“CB”) comes from a payment gateway/bank, following situations may arise:</p>
                <ul>
                    <li>Item not received CB - Buyer hasn't received the item. Refund will be created in accordance with the Terms of Use.</li>
                    <li>Unauthorized CB - Buyer hasn't made this particular transaction. Refund will be created in accordance with the Terms of Use.</li>
                </ul>
                <p><strong>Email Abuse &amp; Threat Policy</strong></p>
                <p>Private communication, including email correspondence, is not regulated by Best Price Flipkart Wholesale. Best Price Flipkart Wholesale encourages its Users to be professional, courteous and respectful when communicating by email.</p>
                <p>However, Best Price Flipkart Wholesale will investigate and can take action on certain types of unwanted emails that violate Best Price Flipkart Wholesale policies.</p>
                <p>Such instances:</p>
                <p>Threats of Bodily Harm - Best Price Flipkart Wholesale does not permit Users to send explicit threats of bodily harm.</p>
                <p>Misuse of Best Price Flipkart Wholesale System - Best Price Flipkart Wholesale allows Users to facilitate transactions through the Best Price Flipkart Wholesale system, but will investigate any misuse of this service.</p>
                <p>Spoof (Fake) email - Best Price Flipkart Wholesale will never ask You to provide sensitive information through email. In case You receive any spoof (fake) email, You are requested to report the same to Us through 'Contact Us' tab.</p>
                <p>Spam (Unsolicited Commercial email) - Best Price Flipkart Wholesale's spam policy applies only to unsolicited commercial messages sent by Best Price Flipkart Wholesale Users. Best Price Flipkart Wholesale Users are not allowed to send spam messages to other Users.</p>
                <p>Offers to Buy or Sell Outside of Best Price Flipkart Wholesale - Best Price Flipkart Wholesale prohibits email offers to buy or sell listed products outside of the Best Price Flipkart Wholesale Platform. Offers of this nature are a potential fraud risk for both Buyers and Sellers.</p>
                <p>Best Price Flipkart Wholesale policy prohibits user-to-user threats of physical harm via any method including, phone, email and on Our public message boards.</p>
                <p>Violations of this policy may result in a range of actions, including:</p>
                <ul>
                    <li>Limits on account privileges</li>
                    <li>Account suspension</li>
                    <li>Cancellation of listings</li>
                    <li>Loss of special status</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Best Price Flipkart Wholesale Infringement Verification (FIV) - Reporting Listing Violations</strong></p>
                <p>Best Price Flipkart Wholesale has put in place Best Price Flipkart Wholesale Infringement Verification process so that intellectual property owners could easily report listings that infringe their rights. It is in Best Price Flipkart Wholesale's interest to ensure that infringing products are removed from the site, as they erode Buyer and good Seller trust.</p>
                <ul>
                    <li>If You are a Verified Rights Owner and want to report a listing issue please contact us at&nbsp;<a href="mailto:infringement@flipkart.com.">infringement@flipkart.com.</a>&nbsp;We request that You follow the format given below if You intend to report an infringement. We may be limited in Our ability to respond to Your request absent reporting in the format as given below.</li>
                </ul>
                <p>Note: Only the intellectual property rights owner can report potentially infringing products or listings through FIV. If You are not the intellectual property rights owner, You can still help by getting in touch with the rights owner and encouraging them to contact Us.</p>
                <ul>
                    <li>If Your listing was removed through FIV, and You believe that Your listing was removed in error, please contact us at&nbsp;<a href="mailto:infringement@flipkart.com">infringement@flipkart.com</a>.</li>
                </ul>
                <p>Best Price Flipkart Wholesale does not and cannot verify that Sellers have the right or ability to sell or distribute their listed products. However, Best Price Flipkart Wholesale is committed to removing infringing or unlicensed products once an authorized representative of the Verified Rights Owner properly reports them to Best Price Flipkart Wholesale.</p>
                <p><strong>Sample Notice of Infringement</strong></p>
                <p>Flipkart India Private Limited</p>
                <p>_______________________</p>
                <p>_______________________</p>
                <p>I,&nbsp;[name]&nbsp;____________________________&nbsp;of&nbsp;[address]&nbsp;_________________________&nbsp;do solemnly and sincerely declare as follows:</p>
                <ol>
                    <li>I am the owner of certain intellectual property rights, said owner being named&nbsp;__________________&nbsp;("IP Owner").</li>
                </ol>
                <ol>
                    <li>I have a good faith belief that the item listings or materials identified in the annexure attached hereto are not authorised by the above IP Owner, its agent, or the law and therefore infringe the IP Owner's rights. Please expeditiously remove or disable access to the material or products claimed to be infringing.</li>
                </ol>
                <ol>
                    <li>I may be contacted at: Name&nbsp;___________________________________________________________</li>
                </ol>
                <p>Title &amp; Company&nbsp;________________________________________________________</p>
                <p>Address&nbsp;_________________________________________________________</p>
                <p>Email (correspondence)&nbsp;___________________________________________________</p>
                <p>Telephone/Fax&nbsp;_____________________________________________________________</p>
                <p>Date&nbsp;_________________________________________________________________</p>
                <p>and I make this declaration conscientiously believing it to be true and correct.</p>
                <p>Declared by&nbsp;______________________________</p>
                <p>on&nbsp;[date]&nbsp;___________________________________&nbsp;in&nbsp;[place]________</p>
                <p>Truthfully,</p>
                <p>Signature</p>
                <p>Addendum to Notice of Infringement:</p>
                <p>List of Allegedly Infringing Listings, Products, or Materials</p>
                <p>A Note on Reason Codes: When identifying item numbers please use the reasons below. When removing products from the site, Best Price Flipkart Wholesale will inform Sellers of the specific reason for the removal of their products.</p>
                <p>Select the most appropriate reason. Please associate each item You report with only one reason code.</p>
                <p>Trademark-infringement</p>
                <ol>
                    <li>Trademark owner doesn't make this type of product or has discontinued the production of the product</li>
                </ol>
                <ol>
                    <li>Item(s) is an unlawful replica of a product made by the trademark owner or is counterfeit Trademark-listing description infringement</li>
                </ol>
                <ol>
                    <li>Listing(s) has unlawful comparison to trademark owner's brand or product</li>
                </ol>
                <ol>
                    <li>Listing(s) contains unlawful use of trademark owner's logo Copyright-item infringement</li>
                </ol>
                <ol>
                    <li>Software is being offered without any license or in violation of a license</li>
                </ol>
                <ol>
                    <li>Item(s) is a bootleg recording;</li>
                </ol>
                <ol>
                    <li>Item(s) is an unlawful copy (software, games, movies, etc.);</li>
                </ol>
                <ol>
                    <li>Item(s) is unlawful duplication of printed material</li>
                </ol>
                <ol>
                    <li>Item(s) is an unlawful copy of other copyrighted work (paintings, sculptures, etc.) Copyright-listing content infringement</li>
                </ol>
                <ol>
                    <li>Listing(s) comprises unauthorized copy of copyrighted text</li>
                </ol>
                <ol>
                    <li>Listing(s) comprises unauthorized copy of copyrighted image</li>
                </ol>
                <ol>
                    <li>Listing(s) comprises unauthorized copy of copyrighted image and text Reason Code:&nbsp;_____________________________________________________________</li>
                </ol>
                <p>Work(s)</p>
                <p>infringed:&nbsp;_________________________________________________________</p>
                <p>Item Number(s):&nbsp;___________________________________________________________</p>
                <p>Note:</p>
                <ol>
                    <li>Please provide the ownership of Trademark (Trademark Registration Certificate should be in the name of applicant)</li>
                </ol>
                <ol>
                    <li>Please provide the evidence as to the ownership of copyright.</li>
                </ol>
                <p>&nbsp;</p>
            </div>
        </>
    )
}

export default Tnc
import React, { useEffect, useState } from 'react'
import CartUi from './CartUi';
import { useNavigate } from 'react-router-dom';
import * as apiHelper from "../../Services/ApiHelper";
import { success, warn } from '../../Wrapper/toast/toast';
import * as toast from '../../Wrapper/toast/toast';
import CartEmpty from '../../Components/CartEmpty/CartEmpty';
import { useDispatch } from 'react-redux';
import { addItems } from '../../Redux/Action/ProductAction';
import MainLoader from '../../Components/_widgets/Loader/MainLoader';
import NoData from '../../Components/Nodata/NoData';


const Cart = () => {
    const navigate = useNavigate()
    let dispatch = useDispatch()
    const [data, setData] = useState(null)
    const [selectedAddress, setSelectedAddress] = useState(0)
    const [selectDefaultAddress, setSelectDefaultAddress] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [address, setAddressData] = useState([])
    const [productQty, setProductQty] = useState([{
        id: '',
        value: ''
    }])
    const [loading, setLoading] = useState(true)
    const [addressLoading, setAddressLoading] = useState(false)
    const [coupon, setCoupon] = useState("")
    const [buttonLoading, setButtonLoading] = useState({})
    const getData = async () => {
        window.scrollTo(0, 0)
        setLoading(true)
        var response = await apiHelper.getCartItem()
        if (response?.isSuccess) {
            setData(response?.data)
            let allQty = []

            response?.data?.items?.map((i) => {

                let qty = {
                    id: i.product_id,
                    value: i.qty || 1
                }

                allQty.push(qty)

            })
            setProductQty(allQty)
            setLoading(false)
        }
    }
    useEffect(() => {
        getData()
        getAddressData()
    }, [])



    const confirmCheckout = () => {
        if (selectedAddress?.id === 0) {
            warn("Please select address");
        }
        else {
            navigate("/checkout", { state: selectedAddress })
        }
    }
    const confirmDefaultAddress = () => {



        setSelectDefaultAddress(true);
        setShowModal(false)
    }
    const handleClose = () => {
        setShowModal(false)

        if (selectDefaultAddress === false) {
            getAddressData()
        }


    }
    const handleClosePayment = () => {
        setShowModal(false)


        getAddressData()



    }
    const applyCoupon = async (id) => {
        var response = await apiHelper.applyCoupon(id)
        if (response?.isSuccess) {
            toast.success(response?.message)
            getData()
        }
        else {
            toast.warn(response?.message)
        }
    }
    const getDefaultAddress = (data) => {
        let completeAddress = ""
        let address = data?.find((i) => i.is_default > 0)
        if (address) {
            completeAddress = `${address?.address} ${address?.state}  ${address?.city} ${address?.pincode}`;
        }
        return completeAddress;
    }
    const getAddressData = async () => {
        var response = await apiHelper.getAddressList()
        if (response?.isSuccess) {
            setAddressData(response?.data)
            setSelectedAddress(response?.data.find((i) => i.is_default > 0))
        }
    }
    const addProduct = async (product) => {

        setButtonLoading(prevState => ({ ...prevState, [product?.id]: true }))
        if (product) {
            let payload = {

                qty: product.value,
                product_id: product.id
            }
            var response = await apiHelper.addToCart(payload)
            if (response?.isSuccess) {
                var cartItem = await apiHelper.getCartItem()

                if (cartItem?.isSuccess) {
                    dispatch(addItems(cartItem?.data))
                }
                getData()
                toast.success(response?.message)
                setButtonLoading(prevState => ({ ...prevState, [product?.id]: false }))
            }
            else {
                setButtonLoading(prevState => ({ ...prevState, [product?.id]: false }))
                toast.warn(response?.message)
            }
        }
    }


    const handleDelectItem = async (item) => {

        if (item?.value > 1) {
            {
                setProductQty((prevQty) =>
                    prevQty.map((i) =>
                        i.id === item.id ? { ...i, value: i.value - 1 } : i
                    ))

            }
        }
    }
    const handleAddItem = (item) => {

        {
            setProductQty((prevQty) =>
                prevQty.map((i) =>
                    i.id === item.id ? { ...i, value: i.value + 1 } : i
                ))

        }
    }
    const getProductQuentity = (item) => {

        if (item) {
            return productQty?.find((i) => i.id === item.id)
        }

    }
    const getProductPrice = (item) => {
        let per_porduct = item?.products?.mrp - item?.products?.discount_price;
        return productQty?.find((i) => i.id === item.product_id).value * per_porduct


    }
    if (loading) {
        return <MainLoader />
    }
    if (data?.order_items === 0) {
        return <CartEmpty />
    }
    const addToWishList = async (product) => {
        setLoading(true)
        let payload = {

            source: "cart",
            product_id: product.product_id,
            qty: 1
        }

        var response = await apiHelper.upsertWishlist(payload)
        if (response?.isSuccess) {
            toast.success(response?.message)
            var cartItem = await apiHelper.getCartItem()

            if (cartItem?.isSuccess) {
                dispatch(addItems(cartItem?.data))
            }
            getData()

        }
        else {
            toast.warn(response?.message)
            setLoading(false)
        }
    }
    const removeToWishList = async (product) => {
        setLoading(true)
        let payload = {


            product_id: product.product_id
        }
        var response = await apiHelper.removeCart(payload)
        if (response?.isSuccess) {
            toast.success(response?.message)
            var cartItem = await apiHelper.getCartItem()

            if (cartItem?.isSuccess) {
                dispatch(addItems(cartItem?.data))
            }
            getData()

        }
        else {
            toast.warn(response?.message)
            setLoading(false)
        }
    }
    const emptyCart = async () => {
        setLoading(true)
        let payload = {


            empty_cart: true
        }
        var response = await apiHelper.emptyCart(payload)
        if (response?.isSuccess) {
            toast.success(response?.message)
            var cartItem = await apiHelper.getCartItem()

            if (cartItem?.isSuccess) {
                dispatch(addItems(cartItem?.data))
            }
            getData()
            setLoading(true)
        }
        else {
            toast.warn(response?.message)
            setLoading(true)
        }
    }
    const goToProduct = () => {
        navigate("/products")
    }
    console.log("datadata", data.items)
    return (
        <>
            {
                data?.items.length > 0 ?
                    <CartUi
                        cartItems={data}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        confirmCheckout={confirmCheckout}
                        getData={getData}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        selectDefaultAddress={selectDefaultAddress}
                        setSelectDefaultAddress={setSelectDefaultAddress}
                        getAddressData={getAddressData}
                        setAddressData={setAddressData}
                        address={address}
                        confirmDefaultAddress={confirmDefaultAddress}
                        addProduct={addProduct}
                        handleDelectItem={handleDelectItem}
                        handleAddItem={handleAddItem}
                        getProductQuentity={getProductQuentity}
                        getProductPrice={getProductPrice}
                        productQty={productQty}
                        setProductQty={setProductQty}
                        buttonLoading={buttonLoading}
                        coupon={coupon}
                        setCoupon={setCoupon}
                        applyCoupon={applyCoupon}
                        removeToWishList={removeToWishList}
                        emptyCart={emptyCart}
                        addToWishList={addToWishList}
                        getDefaultAddress={getDefaultAddress}
                        handleClose={handleClose}
                        setAddressLoading={setAddressLoading}
                        addressLoading={addressLoading}
                        handleClosePayment={handleClosePayment}
                    />
                    : <NoData
                        position={"down"}
                        buttonClick={goToProduct}
                        heading="Your Cart is Empty" />
            }

        </>
    )
}

export default Cart
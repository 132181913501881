// import mixpanel from 'mixpanel-browser';

// mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
// let env_check = true

// let actions = {
//     identify: (id) => {
//         if (env_check) mixpanel.identify(id);
//     },
//     alias: (id) => {
//         if (env_check) mixpanel.alias(id);
//     },
//     track: (name, props) => {

//         if (env_check) mixpanel.track(name, props);
//     },
//     people: {
//         set: (props) => {
//             if (env_check) mixpanel.people.set(props);
//         },
//     },
//     logout: () => {
//         if (env_check) mixpanel.reset();
//     }
// };

// export let Mixpanel = actions;

// window.onerror = function myErrorHandler(errorMsg, url, lineNumber, column, errorObj) {
//     const component = determineComponentFromStack(errorObj?.stack);

//     Mixpanel.track('ERROR', {
//         message: errorMsg,
//         script: url,
//         line: lineNumber,
//         column: column,
//         stack: errorObj?.stack,
//         component: component || 'Unknown Component',
//         cause: analyzeErrorCause(errorMsg, url, lineNumber, column)
//     });

//     return false;
// };


// function determineComponentFromStack(stack) {
//     if (!stack) return null;

//     if (stack.includes('MyComponent')) {
//         return 'MyComponent';
//     }
//     return null;
// }

// function analyzeErrorCause(errorMsg, url, lineNumber, column) {
//     if (errorMsg.includes('TypeError')) {
//         return 'Possibly caused by an undefined variable or object';
//     }
//     return 'Cause unknown';
// }

import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
let env_check = true

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        console.log('tracking', name, props)
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
    logout: () => {
        if (env_check) mixpanel.reset();
    }
};

export let Mixpanel = actions;

window.onerror = function myErrorHandler(errorMsg, url, lineNumber, column, errorObj) {
    const component = determineComponentFromStack(errorObj?.stack);

    Mixpanel.track('ERROR', {
        message: errorMsg,
        script: url,
        line: lineNumber,
        column: column,
        stack: errorObj?.stack,
        component: component || 'Unknown Component',
        cause: analyzeErrorCause(errorMsg, url, lineNumber, column)
    });

    return false;
};


function determineComponentFromStack(stack) {
    if (!stack) return null;

    if (stack.includes('MyComponent')) {
        return 'MyComponent';
    }
    return null;
}

function analyzeErrorCause(errorMsg, url, lineNumber, column) {
    if (errorMsg.includes('TypeError')) {
        return 'Possibly caused by an undefined variable or object';
    }
    return 'Cause unknown';
}


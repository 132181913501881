import { ADDCART, USERLOGOUT } from "../../Constants/actionTypeConst"
const cart = [];

const ProductReducer = (state = cart, action) => {

    switch (action.type) {

        case ADDCART:

            return [action.payload]


        case USERLOGOUT:
            return []

        default:
            return state;
    }
}
export default ProductReducer;
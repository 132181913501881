import * as storageConstants from '../Constants/storageConstant';
import { BROWSER_STORAGE_ERROR } from '../Constants/stringConstants';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import * as apiHelper from "./ApiHelper";
import { formatValue } from 'react-currency-input-field';
import CryptoJS from 'crypto-js';
import { nowDateTime } from '../Utils/dateUtils';

const localStorageArray = [storageConstants.AUTH, storageConstants.MASTER_DATA, storageConstants.PROFILE, storageConstants.USER_ROLE, storageConstants.USER_DATA];
const sessionStorageArray = [storageConstants.SYSTEM_DATA];
const secretKey = process.env.REACT_APP_NAME;

function encryptData(data) {
    return CryptoJS.AES
        .encrypt(typeof data === 'string'
            ? data
            : JSON.stringify(data), secretKey)
        .toString();
}

function decryptData(encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    try {
        return JSON.parse(decryptedText);
    } catch (e) {
        return decryptedText;
    }
}

export function getLocalData(name) {
    try {
        let storedData = null;

        if (sessionStorageArray.includes(name)) {
            storedData = sessionStorage.getItem(name);
        } else if (localStorageArray.includes(name)) {
            storedData = localStorage.getItem(name);
        }

        if (!storedData) return null;

        return decryptData(storedData);
    } catch (e) {
        console.error("Decryption error:", e);
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        const encryptedData = encryptData(data);

        if (sessionStorageArray.includes(name)) {
            sessionStorage.setItem(name, encryptedData);
        } else if (localStorageArray.includes(name)) {
            localStorage.setItem(name, encryptedData);
        }

        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        console.error("Encryption error:", e);
        return false;
    }
}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }
}

export const getMasterData = async () => {
    return getLocalData(storageConstants.MASTER_DATA);
}

export const getSystemData = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}


export function isValidAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const n = a?.toString().replace(/,/g, '');
    return !isNaN(n);
}

export const fetchMasterData = async setLoading => {
    setLoading && setLoading(true);
    const data = getLocalData(storageConstants.MASTER_DATA);
    const query = `?date=${data?.date ?? '2024-10-23 16:05:05'}`;

    const response = await apiHelper.masterData(query);
    setLoading && setLoading(false);
    if (response?.isSuccess) {
        try {
            storeLocalData(storageConstants.MASTER_DATA, response?.data);
        } catch (e) {
            warn(BROWSER_STORAGE_ERROR);
        }
    }
}

export const fetchSystemData = async setLoading => {
    setLoading && setLoading(true);
    const response = await apiHelper.systemData();
    setLoading && setLoading(false);
    if (response?.isSuccess) {
        try {
            storeLocalData(storageConstants.SYSTEM_DATA, response?.data);
        } catch (e) {
            warn(BROWSER_STORAGE_ERROR);
        }
    }
};
export const getTwoDecimalPlaces = (num) => {
    const n = num?.toString();
    return n?.includes('.')
        ? n?.substring(0, n.indexOf('.') + 3)
        : n;
}

export const formattedValue = (val, prefix, allowDecimals = true) => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? "₹" : "",
    intlConfig: prefix ? { locale: 'en-IN', currency: 'INR' } : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export function amountFormatter(amount, type = 'int', rounding = false) {
    if (isNaN(amount)) return amount;
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;

    return type === 'float'
        ? a?.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2, })
        : a?.toLocaleString("en-IN");
}
export function removeCommaFromAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const value = a?.toString().replace(/,/g, '');
    const v = getTwoDecimalPlaces(value);

    return type === 'int'
        ? parseInt(v)
        : parseFloat(v);
}
import { memo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const CustomTooltip = memo(({ text, children, placement }) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
});

export default CustomTooltip;

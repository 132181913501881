import { Mixpanel } from './mixPanel.js';
import { analyticsConstant } from '../Constants/analyticsConstants';
import { getLocalData } from '../Services/common.js';
import { MASTER_DATA } from '../Constants/storageConstant.js';

const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, data);
};

export const KEYS = {
    ID: 'id',
    RESPONSE_TIME_MS: 'Response Time (ms)',
    FULL_URL: 'Full URL',
    ORIGIN: 'ORIGIN',
    ENDPOINT: 'ENDPOINT',
    MSG: 'message',
    STATUS: 'Status',
    USER_NAME: 'user_name',

};

const getDefaultParams = () => {
    const masterData = getLocalData(MASTER_DATA);

    return {
        [KEYS.USER_NAME]: masterData?.name || '-',
        [KEYS.ID]: masterData?.id || '-',
    };
};

export const setUser = (data) => {
    console.log("userData", data?.mno)
    Mixpanel.identify(data.mno);
    Mixpanel.people.set({
        [KEYS.ID]: data?.id,
        $name: data?.first_name,

        $mo: data?.mno
    });
};

export const trackAPITime = (time, url, status) => {
    let baseUrl = url;
    let endPoint = url;

    try {
        const parsedUrl = new URL(url);
        endPoint = parsedUrl.pathname.replace('/api', 'api') || '-';
        baseUrl = parsedUrl.origin;
    } catch (error) {
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        ...getDefaultParams(),
        [KEYS.RESPONSE_TIME_MS]: time,
        [KEYS.STATUS]: status,
        [KEYS.FULL_URL]: url,
        [KEYS.ORIGIN]: baseUrl,
        [KEYS.ENDPOINT]: endPoint,
    });
};

import React from 'react'

const Info = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g fill="none" fill-rule="evenodd">
                <g fill-rule="nonzero">
                    <g>
                        <g>
                            <g>
                                <g fill="#FFF">
                                    <path d="M1.833 3.667c1.013 0 1.834-.821 1.834-1.834C3.667.821 2.846 0 1.833 0 .821 0 0 .82 0 1.833s.82 1.834 1.833 1.834z" transform="translate(-452.000000, -510.000000) translate(420.000000, 494.000000) translate(32.000000, 16.000000) translate(-0.000000, 0.000000) translate(14.166620, 20.492315)" />
                                </g>
                                <g>
                                    <path fill="#027CD5" d="M31 15.512c.005-6.27-3.769-11.926-9.56-14.328C15.648-1.22 8.98.104 4.544 4.536.11 8.968-1.219 15.636 1.18 21.43 3.578 27.222 9.23 31 15.5 31c8.556 0 15.494-6.933 15.5-15.488" transform="translate(-452.000000, -510.000000) translate(420.000000, 494.000000) translate(32.000000, 16.000000) translate(-0.000000, 0.000000) translate(0.333333, 0.333333)" />
                                    <g fill="#FFF">
                                        <path d="M5.45 11.697c0 .776 1.931.72 1.931.72v.869H.343v-.87s1.931.057 1.931-.719V6.002C1.818 5.114 0 4.938 0 4.922V4.43h5.45v7.268zM2.951 0C3.768 0 4.43.661 4.43 1.476s-.66 1.476-1.477 1.476c-.815 0-1.476-.66-1.476-1.476C1.476.661 2.137 0 2.952 0z" transform="translate(-452.000000, -510.000000) translate(420.000000, 494.000000) translate(32.000000, 16.000000) translate(-0.000000, 0.000000) translate(0.333333, 0.333333) translate(11.893601, 8.876269)" />
                                    </g>
                                    <path fill="#333" d="M15.5 30.85C6.985 30.85.083 23.948.083 15.434.084 6.921 6.986.02 15.5.02c2.738.016 5.421.764 7.773 2.165.125.07.217.188.253.327.037.139.016.286-.058.41-.155.256-.486.343-.747.194-2.188-1.288-4.681-1.969-7.22-1.97C9.01 1.15 3.337 5.518 1.675 11.79.014 18.06 2.78 24.666 8.413 27.884c5.634 3.217 12.728 2.242 17.284-2.377 4.557-4.618 5.436-11.725 2.143-17.315-.146-.256-.06-.583.195-.733.254-.15.581-.07.736.181 2.807 4.766 2.846 10.67.105 15.474-2.742 4.803-7.846 7.772-13.377 7.78v-.044z" transform="translate(-452.000000, -510.000000) translate(420.000000, 494.000000) translate(32.000000, 16.000000) translate(-0.000000, 0.000000) translate(0.333333, 0.333333)" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Info
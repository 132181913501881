import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Global.scss';
import "react-datepicker/dist/react-datepicker.css";
import { Provider, ErrorBoundary } from '@rollbar/react'
import { BrowserRouter } from 'react-router-dom';
import { Provider as StateProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './../src/Redux/store'; // Import store and persistor
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: true,
  captureDeviceInfo: true,
  captureEmail: true,
  captureUsername: true,
  enabled: true,
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <StateProvider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </StateProvider>

    </ErrorBoundary>
  </Provider>
  // </React.StrictMode>
);

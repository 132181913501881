import React from 'react'

const Privacy = () => {
    return (
        <>
            <div className="text-start mt-5">
                <h2 className="pt-1 fw-bold about-title mb-3">Privacy Policy</h2>
                <p>Version 2</p>
                <p>WAL-MART INDIA PVT LTD (also referred as “Company”, “we”, “us”, “our”) &nbsp;recognizes the importance of privacy, confidentiality, and security of your personal information and values the trust you place in us. This Privacy Policy cum Notice is published in accordance with the provisions under the Digital Personal Data Protection Act 2023, Information Technology Act, 2000 and other applicable laws that provide rules, regulations, and guidelines on access, collection, usage, disclosure, transfer or otherwise processing of personal information and sensitive personal information in our website&nbsp;<a href="https://www.bestprice.in/bestprice/login">https://www.bestprice.in/bestprice/login</a>&nbsp;or its mobile application, m-site (hereinafter referred as the “Platform”).</p>
                <p>While you may be able to browse certain sections of the Platform without registering with us, however, please note we do not offer any product/service under this Platform outside India. By visiting this Platform, providing your information or availing product/service offered on the Platform, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the&nbsp;<a href="https://www.bestprice.in/tnc">Terms of Use</a>, and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.</p>
                <p><strong>Collection of information</strong></p>
                <p>When you use our Platform, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs and to customize our Platform to make your experience safer and easier.</p>
                <ul>
                    <li><strong><u>Registration &amp; Account Information:</u></strong>&nbsp;We may collect personal information including, but not limited to, name, address, phone number, fax number, email address, gender, date and/or year of birth, government-issued IDs, credit card/debit card / other payment instrument details and user preferences et al, at the time of registration on the Platform. Wherever possible, we indicate which fields are required and which fields are optional. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order) do require registration. We do use your contact information to send you offers based on your previous orders and your interests. We might also receive information about you from other sources and add it to our account information.</li>
                    <li><strong><u>Browsing Information:</u></strong>&nbsp;We may automatically track certain information about you based on your use of our Platform. We use this information to do internal research on our users' demographics, interests, and buying and browsing behavior to better understand, protect, and serve our users. This information is compiled and analyzed on an aggregate basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), the URL you go next to (whether this URL is on our Platform or not), your computer browser information, your location, microphone, your mobile device, including a unique identifier for your device and your IP address. If you choose to buy on the Platform we collect information about your buying behaviour, preferences, and other such information that you choose to provide.</li>
                    <li><strong><u>Transaction Information:</u></strong>&nbsp;If you transact with us, we collect some additional information, such as a billing address, a credit/debit card number and a credit/debit card expiration date, and/ or other payment instrument details and tracking information from cheques or money orders.</li>
                    <li><strong><u>Communication Information:</u></strong>&nbsp;If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback or if you use voice commands to shop on the Platform, we will collect the information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law. Furthermore, we collect all the details pertaining to the user activities over the Platform including but not limited to, the description of goods, pricing and delivery information or any dispute records. If you send us personal correspondence, such as emails or letters or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information into a file specific to you.</li>
                    <li>We shall in no manner be responsible for the accuracy of the personal or other information shared by you to us or to any third party. If you provide any information that is false, inaccurate, non-current or incomplete (or is erroneous, wrong, non-current or incomplete), or else we have sufficient grounds to show if such details are false, wrong, non-current or incomplete, we shall reserve the right to suspend or terminate your account at our sole discretion.</li>
                </ul>
                <p><strong>Use of Demographic / Profile Data / Your Information</strong></p>
                <ul>
                    <li><strong><u>Provision of services and access to Platform:</u></strong>&nbsp;We use personal information to provide the services as per your request. We use your personal information to assist in handling and fulfilling orders, enhancing customer experience, resolving disputes; troubleshooting problems; help promote a safe service; collecting money; measuring consumer interest in our products and services, informing you about online and offline offers, products, services, and updates; customize and enhance your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions, internal training, internal analysis; advertising and promotion; search results and other personalized content; and as otherwise described to you at the time of collection.</li>
                    <li><strong><u>Account registration and enhance services:</u></strong>&nbsp;With your consent, we will have access to your SMS, contacts in your directory, call history, location, microphone and device information and we may request you to provide your Know-Your-Customer (KYC) details to check your eligibility for certain products/services including but not limited to credit and payment products etc., to enhance your experience on the platform and provide you access to the services being offered by us, our affiliates or lending partners. Access, storage and use of this data will be in consonance with the applicable laws. You understand that your access to these products/services may be affected in the event your consent is withdrawn.<br /> In our efforts to continually improve our service offerings, we and our affiliates collect and analyze demographic and profile data about our users' activity on our Platform. We identify and use your IP address to help diagnose problems with our server and to administer our Platform. Your IP address is also used to help in identifying you and to gather broad demographic information.</li>
                    <li><strong><u>Non-marketing Communication:</u></strong>&nbsp;When you register with our Platform, we process your personal information to send you important information regarding the services, changes to our terms, conditions, and policies and/or other administrative information important for us to serve you better and as such you may not opt-out of receiving such communications.</li>
                    <li><strong><u>Marketing Communication</u></strong><strong>:</strong>&nbsp;We may process your personal information to provide marketing and promotional materials to you. To the extent we use your personal information to market services to you we will provide you the ability to opt-out of such email communications. If you have subscribed to DND (Do Not Disturb) you would not be able to receive promotional SMSs however, you may still receive messages that are transactional in nature, such as the progress of shipment to you, etc.</li>
                    <li><strong><u>Surveys:</u></strong>&nbsp;We will occasionally ask you to complete optional online surveys. These surveys may ask you for personal information, contact information, date of birth, demographic information (like zip code, age, or income level), attributes such as your interests, household or lifestyle information, your purchasing behaviour or history, preferences, and other such information that you may choose to provide. We use this data to tailor your experience at our Platform, providing you with content that we think you might be interested in and to display content according to your preferences.</li>
                    <li><strong><u>Legal Compliance:</u></strong>&nbsp;We may process your personal information as we believe to be necessary or appropriate, such as: (a) to comply with applicable laws and regulations; (b) to comply with legal process; (c) to respond to requests from public and government authorities; (d) to enforce our terms and conditions; (e) to protect our operations, business and systems; (f) to protect our rights, privacy, safety or property, and/or that of other users, including you; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
                </ul>
                <p><strong>Cookies</strong></p>
                <p>We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties.</p>
                <p>&nbsp;</p>
                <p><strong>Sharing of your information</strong></p>
                <p>We may share your information with the following recipients:</p>
                <ol>
                    <li>To our third-party service providers who perform certain business-related functions for us, such as website hosting, data analysis, payment and credit card processing, infrastructure provision, IT services, customer support service, e-mail delivery services, and other similar services to enable them to provide services to us.</li>
                    <li>To our customers, sellers and other business partners who provide you, directly or indirectly, with your devices, and/or networks and systems through which you access and use our Platform and services.</li>
                    <li>As we believe to be necessary or appropriate: (a) to comply with applicable laws and regulations; (b) required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, investigations, law enforcement offices, third party rights owners, credit risk reduction, and requests from public and government authorities, including public and government authorities outside your country of residence; (c) to enforce our terms and conditions; (d) to protect our operations, business and systems; (e) to protect our rights, privacy, safety or property, and/or that of other users, including you; and (f) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
                    <li>To our subsidiaries or affiliates within our corporate family, to carry out regular business activities. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.</li>
                    <li>To financing partnerspartner to determine your eligibility and/or modify your credit limit when you apply for loan products or business financing options. The link for the same can be accessed&nbsp;<a href="https://www.bestprice.in/bestprice/creditProgram">here</a>.</li>
                    <li>We may share or sell your information to an affiliate or other third party in the event of any reorganization, merger, sale, joint venture, assignment, business transfer or other disposition of all or any portion of our business, assets or stock (including without limitation in connection with any bankruptcy or similar proceedings). In such an event, you will be notified via email and/or a prominent notice on our Platform of any change in ownership.</li>
                </ol>
                <p><strong>Advertisements on the Platform</strong></p>
                <p>We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>
                <p><strong>Access and Choices:</strong></p>
                <p>You can access a broad range of information about your account and your interactions with us for the purpose of viewing and, in certain cases, updating that information under the profileunder profile information section of your account. This feature may change as our website evolves.</p>
                <p>You always have the option to not provide information by choosing not to use a particular service or feature on the Platform.</p>
                <p>With regards to marketing communication, as described above, we provide all users with the opportunity to opt out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general.</p>
                <p>If you want to remove your contact information from all our lists and newsletters, please choose the unsubscribe option provided in the mailers that you receive from us.</p>
                <p>With regards to cookies, as described above, you are always free to decline our cookies if your browser permits, although in that case, you may not be able to use certain features on the Platform.</p>
                <p><strong>Data Retention</strong></p>
                <p>We retain your Personal Information in accordance with applicable laws for a period no longer than is required for the purposes for which it was collected or as required under any applicable law. However, we may retain data related to you if there is a legal obligation to retain the data; if required to comply with any applicable statutory or regulatory retention requirement by law; if we believe it may be necessary to prevent fraud or future abuse; to enable Flipkart to exercise its legal rights and/or defend against legal claims. We may continue to retain your data in anonymized form for analytical and research purposes.</p>
                <p><strong>Children Data</strong></p>
                <p>We do not knowingly solicit or collect personal information from children under the age of 18 and use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872.</p>
                <p><strong>Your Rights</strong></p>
                <p>We take every reasonable step to ensure that your personal information that we process is accurate and, where necessary, kept up to date, and any of your personal information that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified</p>
                <p>You have the right to request:</p>
                <ul>
                    <li>information about how your personal data is processed,</li>
                    <li>a summary of that personal data collected and processed by us and our affiliated third parties,</li>
                    <li>correction, completion, updation, and erasure of your personal data.</li>
                </ul>
                <p>You can also:</p>
                <ul>
                    <li>withdraw the consent you have provided for processing your personal data,</li>
                    <li>appoint a nominee to manage your personal data on your behalf.</li>
                </ul>
                <p>If you have any of these requests, please contact us.</p>
                <p>&nbsp;</p>
                <p><strong>Security Precautions</strong></p>
                <p>Our Platform adopts reasonable security measures and practices in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to such security guidelines, protecting it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform.</p>
                <p>You are responsible for ensuring the protection of login and password records for your account. You shall inform us promptly of any actual or alleged improper use of your account or password.</p>
                <p>Any personal information you provide when using or relating to our services in any free and public space, including but not limited to any blog, message, network, chat room, discussion page (a) shall not be deemed confidential, (b) shall not be regarded as personal information; and (c) shall not be subject to this Privacy Policy. Since such a public domain or space is accessible to third parties, these third parties may obtain or use your information for their own purposes, you should be cautious to communicate your information in these public contexts. Please note we shall not be held liable for any damages to you or to any third party may suffer as a consequence of your public disclosure of your personal information.</p>
                <p><strong>Changes to this Privacy Policy</strong></p>
                <p>We may update this Privacy Policy to reflect changes to our information practices We will alert you to material changes by, for example, placing a notice on our websites/app; posting the date our policy got last updated at the top of the Privacy Policy; or by sending you an email, when we are required to do so by applicable law. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
                <p><strong>Consent</strong></p>
                <ul>
                    <li>By using our Platform or by providing your information, you consent to us for collecting, storing, processing, transferring and sharing information (including sensitive personal information) related to you in accordance with this Privacy Policy. If you disclose any personal information relating to other people to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</li>
                    <li>You, while providing your personal information over the Platform or any partner platforms, expressly consent to us (including our other corporate entities and affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail.</li>
                    <li>You have the option to withdraw your consent that you have already provided by sending us such request in writing to us at the contact information provided below. Please mention “for withdrawal of consent” in your subject line of your communication. We will verify such request before acting on us request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related terms of use and applicable laws. In the event that you withdraw consent given to us under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for which we consider such information to be necessary.</li>
                </ul>
                <p><strong>Contact us</strong></p>
                <p><strong><br /></strong>If your query / complaint not resolved needs to be escalated: As per the applicable laws, WAL-MART INDIA PVT LTD has appointed a “Grievance Officer” to address your grievances.</p>
                <p><br /> Here are the details for Grievance officer:</p>
                <p><br /> Mr. Sahil Thakur<br /> Email ID :&nbsp;<a href="mailto:grievance-officer@walmart.com">grievance-officer@walmart.com</a><br /> Designation : Associate Director<br /> Flipkart Internet Pvt Ltd Block A,<br /> 6th Floor Embassy Tech Village,<br /> Outer Ring Road, Devarabeesanahalli Village,<br /> Varthur Hobli, Bengaluru East Taluk,<br /> Bengaluru District, Karnataka : 560103, India</p>
                <p>Our ‘Grievance Redressal Mechanism’ is as follows:</p>
                <ul>
                    <li>
                        Upon the receipt of a Consumer Grievance on the channels specified above.
                        <ul>
                            <li>The Consumer shall receive an acknowledgment for its grievance within 48 (Forty-Eight) hours through email OR phone call or SMS, and</li>
                        </ul>
                    </li>
                    <li>“Consumer Care” and “Grievance Officer” shall take all the best endeavors to resolve the grievance as expeditiously within the timeline as prescribed in the applicable laws.</li>
                    <li>
                        A Grievance will be considered as closed and disposed-off and in any of the following instances, namely:
                        <ul>
                            <li>When the consumer is communicated by Consumer Care / Grievance Officer / any other person associated with the website and offers solutions to its grievance</li>
                        </ul>
                    </li>
                </ul>
                <p>For more details, please visit Terms of Use</p>
                <p>Last Updated – October 2024</p>
                <section className="section-categories">
                    <div className="">
                        <div className="">
                            <div className="page-sidebar">
                                <div className="bp-category bp-category-inner-page js-categoryBox">
                                    <div className="bp-category-mobile-header">
                                        <div className="box-mobile-header center-xy"><a className="go-back" href="javascript:void(0)" aria-label="Go Back"><em className="icon icon-left-arrow" aria-hidden="true"></em></a><span className="title text-uppercase">Categories</span></div>
                                    </div>
                                    <ul className="bp-category-parent" role="category" aria-orientation="vertical">
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/hc8/he5/9222150258718/Personal%20Care.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Personal care</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Hair-Care/c/20049">Hair Care</a><a className="mob-only" href="javascript:void(0);">Hair Care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Hair-Care/Shampoos-%26-Conditioners/c/30176">Shampoos &amp; Conditioners</a></li>
                                                        <li><a href="/bestprice/Personal-care/Hair-Care/Hair-Oil-%26-Serums/c/30178">Hair Oil &amp; Serums</a></li>
                                                        <li><a href="/bestprice/Personal-care/Hair-Care/Hair-Colors-%26-Dyes/c/30179">Hair Colors &amp; Dyes</a></li>
                                                        <li><a href="/bestprice/Personal-care/Hair-Care/Hair-Gels-%26-Creams/c/30177">Hair Gels &amp; Creams</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Skin-Care/c/20048">Skin Care</a><a className="mob-only" href="javascript:void(0);">Skin Care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Creams-%26-Body-Lotions/c/30169">Creams &amp; Body Lotions</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Face-Washes/c/30173">Face Washes</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Sunscreen/c/30171">Sunscreen</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Hair-Removers/c/30172">Hair Removers</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Facial-Kits-%26-Bleaches/c/30174">Facial Kits &amp; Bleaches</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Facial-Wipes/c/30175">Facial Wipes</a></li>
                                                        <li><a href="/bestprice/Personal-care/Skin-Care/Eye%2C-Lip-%26-Ear-care/c/30170">Eye, Lip &amp; Ear care</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Soaps-%26-Body-wash/c/20052">Soaps &amp; Body wash</a><a className="mob-only" href="javascript:void(0);">Soaps &amp; Body wash</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Soaps-%26-Body-wash/Bar-Soap/c/30187">Bar Soap</a></li>
                                                        <li><a href="/bestprice/Personal-care/Soaps-%26-Body-wash/Hand-Wash/c/30188">Hand Wash</a></li>
                                                        <li><a href="/bestprice/Personal-care/Soaps-%26-Body-wash/Hand-Sanitizers/c/30189">Hand Sanitizers</a></li>
                                                        <li><a href="/bestprice/Personal-care/Soaps-%26-Body-wash/Shower-Gel-%26-Body-Wash/c/30190">Shower Gel &amp; Body Wash</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Dental-care/c/20051">Dental care</a><a className="mob-only" href="javascript:void(0);">Dental care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Dental-care/Tooth-Paste-%26-Powder/c/30184">Tooth Paste &amp; Powder</a></li>
                                                        <li><a href="/bestprice/Personal-care/Dental-care/Tooth-Brush/c/30185">Tooth Brush</a></li>
                                                        <li><a href="/bestprice/Personal-care/Dental-care/Mouth-Wash/c/30186">Mouth Wash</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Deos%2C-Talcs-%26-Perfumes/c/20047">Deos, Talcs &amp; Perfumes</a><a className="mob-only" href="javascript:void(0);">Deos, Talcs &amp; Perfumes</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Deos%2C-Talcs-%26-Perfumes/Talcs/c/30165">Talcs</a></li>
                                                        <li><a href="/bestprice/Personal-care/Deos%2C-Talcs-%26-Perfumes/Men%27s-Deos-%26-Sprays/c/30166">Men's Deos &amp; Sprays</a></li>
                                                        <li><a href="/bestprice/Personal-care/Deos%2C-Talcs-%26-Perfumes/Women%27s-Deos-%26-Sprays/c/30168">Women's Deos &amp; Sprays</a></li>
                                                        <li><a href="/bestprice/Personal-care/Deos%2C-Talcs-%26-Perfumes/Perfumes/c/30167">Perfumes</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Baby-Care/c/20054">Baby Care</a><a className="mob-only" href="javascript:void(0);">Baby Care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Baby-Care/Baby-Diaper-%26-Wipes/c/30194">Baby Diaper &amp; Wipes</a></li>
                                                        <li><a href="/bestprice/Personal-care/Baby-Care/Baby-Soaps-%26-Shampoos/c/30195">Baby Soaps &amp; Shampoos</a></li>
                                                        <li><a href="/bestprice/Personal-care/Baby-Care/Baby-Oil-%26-Powder/c/30193">Baby Oil &amp; Powder</a></li>
                                                        <li><a href="/bestprice/Personal-care/Baby-Care/Baby-Food/c/30196">Baby Food</a></li>
                                                        <li><a href="/bestprice/Personal-care/Baby-Care/Baby-Cream-%26-Lotions/c/30197">Baby Cream &amp; Lotions</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Sanitary-Needs/c/20053">Sanitary Needs</a><a className="mob-only" href="javascript:void(0);">Sanitary Needs</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Sanitary-Needs/Sanitary-Pads/c/30191">Sanitary Pads</a></li>
                                                        <li><a href="/bestprice/Personal-care/Sanitary-Needs/Intimate-Wash/c/30192">Intimate Wash</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Personal-care/Shaving-Needs/c/20050">Shaving Needs</a><a className="mob-only" href="javascript:void(0);">Shaving Needs</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Personal-care/Shaving-Needs/Razors%2C-Blade-%26-Cartridge/c/30182">Razors, Blade &amp; Cartridge</a></li>
                                                        <li><a href="/bestprice/Personal-care/Shaving-Needs/Shaving-Creams%2C-Gels-%26-Foams/c/30180">Shaving Creams, Gels &amp; Foams</a></li>
                                                        <li><a href="/bestprice/Personal-care/Shaving-Needs/After-Shave/c/30181">After Shave</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h50/h72/9222151503902/Homecare.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Home care</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Laundry/c/20012">Laundry</a><a className="mob-only" href="javascript:void(0);">Laundry</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Laundry/Detergent-Powder/c/30057">Detergent Powder</a></li>
                                                        <li><a href="/bestprice/Home-care/Laundry/Detergent-Bar/c/30060">Detergent Bar</a></li>
                                                        <li><a href="/bestprice/Home-care/Laundry/Fabric-Enhancer/c/30056">Fabric Enhancer</a></li>
                                                        <li><a href="/bestprice/Home-care/Laundry/Liquid-Detergent/c/30059">Liquid Detergent</a></li>
                                                        <li><a href="/bestprice/Home-care/Laundry/Fabric-Whitener/c/30058">Fabric Whitener</a></li>
                                                        <li><a href="/bestprice/Home-care/Laundry/Stain-Remover/c/30055">Stain Remover</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/c/20010">House &amp; Kitchen Cleaning</a><a className="mob-only" href="javascript:void(0);">House &amp; Kitchen Cleaning</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Dishwash/c/30047">Dishwash</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Toilet-Cleaners/c/30050">Toilet Cleaners</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Floor-Cleaners-%26-Phenyls/c/30051">Floor Cleaners &amp; Phenyls</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Cleaning-Tools/c/30048">Cleaning Tools</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Glass-%26-Household-Cleaners/c/30046">Glass &amp; Household Cleaners</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Naphthalene-Balls/c/30049">Naphthalene Balls</a></li>
                                                        <li><a href="/bestprice/Home-care/House-%26-Kitchen-Cleaning/Fruit-and-Vegetable-Wash/c/30336">Fruit and Vegetable Wash</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Home-Utilities/c/20011">Home Utilities</a><a className="mob-only" href="javascript:void(0);">Home Utilities</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Home-Utilities/Batteries/c/30054">Batteries</a></li>
                                                        <li><a href="/bestprice/Home-care/Home-Utilities/Pest-Control/c/30053">Pest Control</a></li>
                                                        <li><a href="/bestprice/Home-care/Home-Utilities/Torches/c/30052">Torches</a></li>
                                                        <li><a href="/bestprice/Home-care/Home-Utilities/Home-D%C3%A9cor-%26-Fragrance/c/30265">Home Décor &amp; Fragrance</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Air-%26-Car-Fresheners/c/20015">Air &amp; Car Fresheners</a><a className="mob-only" href="javascript:void(0);">Air &amp; Car Fresheners</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Air-%26-Car-Fresheners/Room-Fresheners/c/30065">Room Fresheners</a></li>
                                                        <li><a href="/bestprice/Home-care/Air-%26-Car-Fresheners/Car-Fresheners/c/30066">Car Fresheners</a></li>
                                                        <li><a href="/bestprice/Home-care/Air-%26-Car-Fresheners/Sanitary-Cubes-%26-Blocks/c/30277">Sanitary Cubes &amp; Blocks</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Shoe-Care/c/20013">Shoe Care</a><a className="mob-only" href="javascript:void(0);">Shoe Care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Shoe-Care/Shoe-Polish/c/30061">Shoe Polish</a></li>
                                                        <li><a href="/bestprice/Home-care/Shoe-Care/Shoe-Brush/c/30062">Shoe Brush</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Pooja-Needs/c/20014">Pooja Needs</a><a className="mob-only" href="javascript:void(0);">Pooja Needs</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Pooja-Needs/Agarbatti-%26-Dhoop/c/30063">Agarbatti &amp; Dhoop</a></li>
                                                        <li><a href="/bestprice/Home-care/Pooja-Needs/Other-Pooja-Needs/c/30064">Other Pooja Needs</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Home-care/Pet-Care/c/20016">Pet Care</a><a className="mob-only" href="javascript:void(0);">Pet Care</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Home-care/Pet-Care/Pet-Food/c/30068">Pet Food</a></li>
                                                        <li><a href="/bestprice/Home-care/Pet-Care/Pet-Accessories/c/30067">Pet Accessories</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h04/hf7/9227517558814/Oil,%20Sugar%20and%20Masalas.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Oil, Sugar &amp; Masalas</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/c/20063">Oil &amp; Ghee</a><a className="mob-only" href="javascript:void(0);">Oil &amp; Ghee</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Soybean-Oil/c/30233">Soybean Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Desi-Ghee/c/30240">Desi Ghee</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Sunflower-Oil/c/30232">Sunflower Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Mustard-Oil/c/30236">Mustard Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Healthy-Oil/c/30237">Healthy Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Vanaspati/c/30231">Vanaspati</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Groundnut-Oil/c/30238">Groundnut Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Olive-%26-Canola-Oil/c/30230">Olive &amp; Canola Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Palm-Oil/c/30235">Palm Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Rice-Bran-Oil/c/30234">Rice Bran Oil</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Other-Oils/c/30239">Other Oils</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Oil-%26-Ghee/Coconut-Oil/c/30286">Coconut Oil</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/c/20061">Masala Spices &amp; Ingredients</a><a className="mob-only" href="javascript:void(0);">Masala Spices &amp; Ingredients</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/Powdered-Spices/c/30223">Powdered Spices</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/Whole-Spices/c/30225">Whole Spices</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/Salt/c/30224">Salt</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/Baking-%26-Other-Cooking-Needs/c/30226">Baking &amp; Other Cooking Needs</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Masala-Spices-%26-Ingredients/Seasoning/c/30222">Seasoning</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Oil%2C-Sugar-%26-Masalas/Sugar-%26-Gud/c/20062">Sugar &amp; Gud</a><a className="mob-only" href="javascript:void(0);">Sugar &amp; Gud</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Sugar-%26-Gud/Sugar%2C-Gud-%26-Mishri/c/30228">Sugar, Gud &amp; Mishri</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Sugar-%26-Gud/Sugar-Cubes-%26-Sachets/c/30229">Sugar Cubes &amp; Sachets</a></li>
                                                        <li><a href="/bestprice/Oil%2C-Sugar-%26-Masalas/Sugar-%26-Gud/Sugar-Free/c/30227">Sugar Free</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h36/hed/9227517296670/Rice,%20Atta%20and%20Dals.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Rice, Atta &amp; Dals</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/c/20056">Dals &amp; Pulses</a><a className="mob-only" href="javascript:void(0);">Dals &amp; Pulses</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Chana/c/30210">Chana</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Tur-Arhar-Sambhar-Dal/c/30207">Tur/Arhar/Sambhar Dal</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Moong/c/30208">Moong</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Urad-Maa-ki-dal/c/30206">Urad/Maa ki dal</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Rajma%2C-Matar-%26-Other-Dals/c/30211">Rajma, Matar &amp; Other Dals</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Raw-Peanuts/c/30205">Raw Peanuts</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Masoor/c/30209">Masoor</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dals-%26-Pulses/Soya-Chunks/c/30204">Soya Chunks</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Rice%2C-Atta-%26-Dals/Rice-%26-Rice-Products/c/20058">Rice &amp; Rice Products</a><a className="mob-only" href="javascript:void(0);">Rice &amp; Rice Products</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Rice-%26-Rice-Products/Basmati/c/30220">Basmati</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Rice-%26-Rice-Products/Rice/c/30219">Rice</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Rice-%26-Rice-Products/Poha-%26-Murmura/c/30218">Poha &amp; Murmura</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Rice-%26-Rice-Products/Organic-%26-Brown-Rice/c/30221">Organic &amp; Brown Rice</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/c/20055">Dry Fruits</a><a className="mob-only" href="javascript:void(0);">Dry Fruits</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Almond/c/30202">Almond</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Other-Dry-Fruits/c/30198">Other Dry Fruits</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Cashew/c/30201">Cashew</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Pista-%26-Rasins/c/30200">Pista &amp; Rasins</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Seeds/c/30199">Seeds</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Dry-Fruits/Dates-%26-Prunes/c/30203">Dates &amp; Prunes</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/c/20057">Atta &amp; Flours</a><a className="mob-only" href="javascript:void(0);">Atta &amp; Flours</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Atta/c/30214">Atta</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Suji%2C-Sabudana-%26-Daliya/c/30212">Suji, Sabudana &amp; Daliya</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Besan/c/30216">Besan</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Maida/c/30215">Maida</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Instant-Mixes/c/30213">Instant Mixes</a></li>
                                                        <li><a href="/bestprice/Rice%2C-Atta-%26-Dals/Atta-%26-Flours/Organic-Atta-%26-Other-Flours/c/30217">Organic Atta &amp; Other Flours</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h1a/hff/9227517820958/Packaged%20Food.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Packaged Foods &amp; Dry Fruits</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/c/20037">Biscuits</a><a className="mob-only" href="javascript:void(0);">Biscuits</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Crackers%2C-Sweet-%26-Salty/c/30131">Crackers, Sweet &amp; Salty</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Cream-Biscuits/c/30130">Cream Biscuits</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Cookies/c/30132">Cookies</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Health-%26-Marie/c/30128">Health &amp; Marie</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Glucose-%26-Milk/c/30129">Glucose &amp; Milk</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Biscuits/Wafers/c/30127">Wafers</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Namkeen%2C-Chips-%26-Savory/c/20036">Namkeen, Chips &amp; Savory</a><a className="mob-only" href="javascript:void(0);">Namkeen, Chips &amp; Savory</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Namkeen%2C-Chips-%26-Savory/Chips-%26-Nachos/c/30122">Chips &amp; Nachos</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Namkeen%2C-Chips-%26-Savory/Namkeen-%26-Mixtures/c/30123">Namkeen &amp; Mixtures</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Namkeen%2C-Chips-%26-Savory/Pop-Corn/c/30121">Pop Corn</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Festive-%26-Gifting/c/30120">Festive &amp; Gifting</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Noodles%2C-Pasta-%26-Instant-Foods/c/20039">Noodles, Pasta &amp; Instant Foods</a><a className="mob-only" href="javascript:void(0);">Noodles, Pasta &amp; Instant Foods</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Noodles%2C-Pasta-%26-Instant-Foods/Noodles%2C-Pasta-%26-Vermicelli/c/30137">Noodles, Pasta &amp; Vermicelli</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Noodles%2C-Pasta-%26-Instant-Foods/Soups-%26-Tastemakers/c/30138">Soups &amp; Tastemakers</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Noodles%2C-Pasta-%26-Instant-Foods/Papad-%26-Fryums/c/30139">Papad &amp; Fryums</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/c/20035">Chocolates &amp; Toffees</a><a className="mob-only" href="javascript:void(0);">Chocolates &amp; Toffees</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Chocolates/c/30119">Chocolates</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Toffee%2C-Candy-%26-Eclairs/c/30116">Toffee, Candy &amp; Eclairs</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Gums-%26-Mints/c/30117">Gums &amp; Mints</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Digestives-%26-Mouth-Freshners/c/30118">Digestives &amp; Mouth Freshners</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Chocolates-%26-Toffees/Festive-%26-Gifting/c/30120">Festive &amp; Gifting</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/c/20040">Jams, Sauces &amp; Pickles</a><a className="mob-only" href="javascript:void(0);">Jams, Sauces &amp; Pickles</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Sauces-%26-Ketchup/c/30140">Sauces &amp; Ketchup</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Jams%2C-Spreads-%26-Mayonnaise/c/30144">Jams, Spreads &amp; Mayonnaise</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Pickles/c/30143">Pickles</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Honey/c/30145">Honey</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Pastes-%26-Purees/c/30142">Pastes &amp; Purees</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Jams%2C-Sauces-%26-Pickles/Canned-%26-Preserved-Foods/c/30141">Canned &amp; Preserved Foods</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Breakfast-Cereals/c/20038">Breakfast Cereals</a><a className="mob-only" href="javascript:void(0);">Breakfast Cereals</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Breakfast-Cereals/Chocos-%26-Corn-Flakes/c/30134">Chocos &amp; Corn Flakes</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Breakfast-Cereals/Oats/c/30136">Oats</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Breakfast-Cereals/Muesli-%26-Wheat-Flakes/c/30135">Muesli &amp; Wheat Flakes</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Indian-Sweets/c/20041">Indian Sweets</a><a className="mob-only" href="javascript:void(0);">Indian Sweets</a>
                                                    <ul></ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Bakery/c/20073">Bakery</a><a className="mob-only" href="javascript:void(0);">Bakery</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Bakery/Cakes%2C-Muffins-%26-Brownies/c/30125">Cakes, Muffins &amp; Brownies</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Bakery/Rusk-%26-Khari/c/30124">Rusk &amp; Khari</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Bakery/Bread%2C-Bun-%26-Pav/c/30133">Bread, Bun &amp; Pav</a></li>
                                                        <li><a href="/bestprice/Packaged-Foods-%26-Dry-Fruits/Bakery/Festive-Gift-Packs/c/30126">Festive Gift Packs</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/hdd/h7f/9222151110686/everages.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Beverages</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Beverages/Tea-%26-Coffee/c/20034">Tea &amp; Coffee</a><a className="mob-only" href="javascript:void(0);">Tea &amp; Coffee</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Beverages/Tea-%26-Coffee/Tea-%26-Iced-Tea/c/30114">Tea &amp; Iced Tea</a></li>
                                                        <li><a href="/bestprice/Beverages/Tea-%26-Coffee/Coffee/c/30115">Coffee</a></li>
                                                        <li><a href="/bestprice/Beverages/Tea-%26-Coffee/Tea-Bags/c/30268">Tea Bags</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Beverages/Soft-Drinks-%26-Soda/c/20033">Soft Drinks &amp; Soda</a><a className="mob-only" href="javascript:void(0);">Soft Drinks &amp; Soda</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Cola/c/30113">Cola</a></li>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Lemonade/c/30111">Lemonade</a></li>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Sports-%26-Energy-Drink/c/30108">Sports &amp; Energy Drink</a></li>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Orange/c/30110">Orange</a></li>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Cans/c/30112">Cans</a></li>
                                                        <li><a href="/bestprice/Beverages/Soft-Drinks-%26-Soda/Soda/c/30109">Soda</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Beverages/Juices-%26-Fruit-Drinks/c/20032">Juices &amp; Fruit Drinks</a><a className="mob-only" href="javascript:void(0);">Juices &amp; Fruit Drinks</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Beverages/Juices-%26-Fruit-Drinks/Fruit-Drinks/c/30104">Fruit Drinks</a></li>
                                                        <li><a href="/bestprice/Beverages/Juices-%26-Fruit-Drinks/Juices/c/30107">Juices</a></li>
                                                        <li><a href="/bestprice/Beverages/Juices-%26-Fruit-Drinks/Powdered-Drinks/c/30105">Powdered Drinks</a></li>
                                                        <li><a href="/bestprice/Beverages/Juices-%26-Fruit-Drinks/Syrups-%26-Squash/c/30106">Syrups &amp; Squash</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Beverages/Drinks/c/20030">Drinks</a><a className="mob-only" href="javascript:void(0);">Drinks</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Beverages/Drinks/Brown-Drinks/c/30102">Brown Drinks</a></li>
                                                        <li><a href="/bestprice/Beverages/Drinks/White-Drinks/c/30103">White Drinks</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Beverages/Water/c/20031">Water</a><a className="mob-only" href="javascript:void(0);">Water</a>
                                                    <ul></ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h29/hd5/9222150717470/Daily%20Fresh%20and%20Frozen.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Dairy, Fresh &amp; Frozen</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/c/20042">Dairy</a><a className="mob-only" href="javascript:void(0);">Dairy</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Milk-%26-Milk-Powder/c/30146">Milk &amp; Milk Powder</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Butter-%26-Margarine/c/30147">Butter &amp; Margarine</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Cheese-And-Paneer/c/30148">Cheese And Paneer</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Milk-Cream/c/30153">Milk Cream</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Milk-Based-Drink/c/30151">Milk Based Drink</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Condensed-Milk/c/30150">Condensed Milk</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Buttermilk-And-Lassi/c/30152">Buttermilk And Lassi</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Curd%2C-Yogurts-%26-Shrikhand/c/30149">Curd, Yogurts &amp; Shrikhand</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Ice-Cream-%26-Frozen-Desert/c/30154">Ice Cream &amp; Frozen Desert</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Cheese/c/30270">Cheese</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Milk-Powder/c/30283">Milk Powder</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Milk/c/30272">Milk</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Frozen-Fruits-%26-Pulp/c/30269">Frozen Fruits &amp; Pulp</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Dairy/Paneer/c/30271">Paneer</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Veg/c/20043">Frozen Veg</a><a className="mob-only" href="javascript:void(0);">Frozen Veg</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Veg/Ready-To-Fry/c/30156">Ready To Fry</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Veg/Frozen-Peas-%26-Corn/c/30157">Frozen Peas &amp; Corn</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Veg/Ready-To-Cook-%26-Eat/c/30155">Ready To Cook &amp; Eat</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Fruits-%26-Vegetables/c/20045">Fruits &amp; Vegetables</a><a className="mob-only" href="javascript:void(0);">Fruits &amp; Vegetables</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Fruits-%26-Vegetables/Vegetables-%26-Fruits/c/30161">Vegetables &amp; Fruits</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Fruits-%26-Vegetables/Imported-Fruits/c/30162">Imported Fruits</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Fruits-%26-Vegetables/Vegetables/c/30273">Vegetables</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Fruits-%26-Vegetables/Fruits/c/30274">Fruits</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Non-Veg/c/20044">Frozen Non Veg</a><a className="mob-only" href="javascript:void(0);">Frozen Non Veg</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Non-Veg/Chicken/c/30159">Chicken</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Non-Veg/Fish-%26-Sea-Products/c/30160">Fish &amp; Sea Products</a></li>
                                                        <li><a href="/bestprice/Dairy%2C-Fresh-%26-Frozen/Frozen-Non-Veg/Mutton-%26-Pork/c/30158">Mutton &amp; Pork</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h3e/h94/9227516772382/Restaurant%20Supplies%20and%20Housewares.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Restaurant Supplies &amp; Houseware</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/c/20018">Kitchen &amp; Tableware</a><a className="mob-only" href="javascript:void(0);">Kitchen &amp; Tableware</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Tiffin%2C-Bottles-%26-Sippers/c/30082">Tiffin, Bottles &amp; Sippers</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Kitchen-Storage/c/30078">Kitchen Storage</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Cutlery-%26-Kitchen-Tools/c/30084">Cutlery &amp; Kitchen Tools</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Cups%2C-Tea-Sets-%26-Trays/c/30087">Cups, Tea Sets &amp; Trays</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Plates-%26-Bowls/c/30079">Plates &amp; Bowls</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Gift-Packs/c/30086">Gift Packs</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Thermoware/c/30077">Thermoware</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Dinner-Sets/c/30080">Dinner Sets</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Special-Order/c/30083">Special Order</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Serving-Utensils/c/30288">Serving Utensils</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Kitchen-%26-Tableware/Cookware/c/30287">Cookware</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/c/20017">Paper Goods &amp; Disposables</a><a className="mob-only" href="javascript:void(0);">Paper Goods &amp; Disposables</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Foil%2CWraps-%26-Food-Bags/c/30069">Foil,Wraps &amp; Food Bags</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Tissue-Paper-%26-Wet-Wipes/c/30076">Tissue Paper &amp; Wet Wipes</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Kitchen-Towels-%26-Toilet-Rolls/c/30073">Kitchen Towels &amp; Toilet Rolls</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Garbage-Bags/c/30070">Garbage Bags</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Glass%2C-Cups-%26-Lids/c/30071">Glass, Cups &amp; Lids</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Plates%2C-Bowls-%26-Cutlery/c/30072">Plates, Bowls &amp; Cutlery</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Paper-Goods-%26-Disposables/Special-Order/c/30075">Special Order</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/c/20019">Home Textile</a><a className="mob-only" href="javascript:void(0);">Home Textile</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Towels/c/30088">Towels</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Bed-Sheets-%26-Table-Linen/c/30090">Bed Sheets &amp; Table Linen</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Mats-%26-Rugs/c/30096">Mats &amp; Rugs</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Mattress/c/30095">Mattress</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Blanket-%26-Quilts/c/30093">Blanket &amp; Quilts</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/White-Linen/c/30091">White Linen</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Pillows-%26-Cushions/c/30094">Pillows &amp; Cushions</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Curtains-%26-Blinds/c/30092">Curtains &amp; Blinds</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Home-Textile/Special-Order/c/30089">Special Order</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/c/20020">Houseware</a><a className="mob-only" href="javascript:void(0);">Houseware</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/All-Bathroom-Accessories/c/30100">All Bathroom Accessories</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/Other-House-Utilities/c/30098">Other House Utilities</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/Umbrella-%26-Seasonal-Products/c/30099">Umbrella &amp; Seasonal Products</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/Home-D%C3%A9cor-%26-Fragrances/c/30097">Home Décor &amp; Fragrances</a></li>
                                                        <li><a href="/bestprice/Restaurant-Supplies-%26-Houseware/Houseware/Special-Order/c/30101">Special Order</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/hb8/he5/9227517034526/Stationary%20and%20Office%20Furniture.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>IT, Stationery &amp; Office Furniture</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/c/20007">Office Stationery</a><a className="mob-only" href="javascript:void(0);">Office Stationery</a>
                                                    <ul>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Paper/c/30040">Paper</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Adhesvie-%26-Tapes/c/30035">Adhesvie &amp; Tapes</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Pens/c/30034">Pens</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Files-%26-Folders/c/30039">Files &amp; Folders</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Stapler%2C-Punches-%26-Pins/c/30038">Stapler, Punches &amp; Pins</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Calculator/c/30042">Calculator</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Executive-Notebooks-%26-Writing-Pads/c/30036">Executive Notebooks &amp; Writing Pads</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Scissors-%26-Paper-Cutters/c/30037">Scissors &amp; Paper Cutters</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Safety-%26-Security/c/30041">Safety &amp; Security</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Markers-%26-Highlighters/c/30282">Markers &amp; Highlighters</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Office-Stationery/Special-Order/c/30299">Special Order</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/c/20006">Student Stationery &amp; Toys</a><a className="mob-only" href="javascript:void(0);">Student Stationery &amp; Toys</a>
                                                    <ul>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Books-%26-Notebooks/c/30029">Books &amp; Notebooks</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Pencils/c/30026">Pencils</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Colors-%26-Craft/c/30031">Colors &amp; Craft</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Erasers-%26-Sharpeners/c/30033">Erasers &amp; Sharpeners</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Other-Student-Stationery/c/30025">Other Student Stationery</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Pencil-Box-%26-Pouches/c/30027">Pencil Box &amp; Pouches</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Children%27s-Books-%26-Stationery/c/30028">Children's Books &amp; Stationery</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Toys/c/30032">Toys</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Student-Stationery-%26-Toys/Gift-Wrapping-Paper-%26-Shagun-envelop/c/30074">Gift Wrapping Paper &amp; Shagun envelop</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Furniture/c/20008">Furniture</a><a className="mob-only" href="javascript:void(0);">Furniture</a>
                                                    <ul>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Furniture/Horeca-Furniture/c/30278">Horeca Furniture</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Furniture/Plastic-Furniture/c/30280">Plastic Furniture</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Furniture/Office-Furniture/c/30279">Office Furniture</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Computers-Accessories/c/20004">Computers Accessories</a><a className="mob-only" href="javascript:void(0);">Computers Accessories</a>
                                                    <ul>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Computers-Accessories/Power-Banks-%26-UPS/c/30014">Power Banks &amp; UPS</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Computers-Accessories/Other-Computer-Accessories/c/30015">Other Computer Accessories</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Computers-Accessories/Printers-%26-Scanners/c/30013">Printers &amp; Scanners</a></li>
                                                        <li><a href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Computers-Accessories/Special-Order/c/30030">Special Order</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/IT%2C-Stationery-%26-Office-Furniture/Bags/c/20009">Bags</a><a className="mob-only" href="javascript:void(0);">Bags</a>
                                                    <ul></ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/he0/hed/9225910878238/Luggage%20and%20Apparel.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Luggage &amp; Apparel</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/c/20066">Apparel &amp; Footwear</a><a className="mob-only" href="javascript:void(0);">Apparel &amp; Footwear</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Vests/c/30253">Vests</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Handkerchiefs-%26-Socks/c/30248">Handkerchiefs &amp; Socks</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Women-%26-Kid%E2%80%99s-Wear/c/30247">Women &amp; Kid’s Wear</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Other-Men%E2%80%99s-Wear/c/30249">Other Men’s Wear</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Briefs/c/30252">Briefs</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Footwear/c/30254">Footwear</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Ready-to-Stitch/c/30251">Ready to Stitch</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Apparel-%26-Footwear/Thermal-Wear/c/30250">Thermal Wear</a></li>
                                                    </ul>

                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/c/20065">Bags &amp; Luggage</a><a className="mob-only" href="javascript:void(0);">Bags &amp; Luggage</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Trolley-Bags/c/30242">Trolley Bags</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/School-Bag/c/30246">School Bag</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Backpacks/c/30243">Backpacks</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Handbags-%26-Carry-Bags/c/30245">Handbags &amp; Carry Bags</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Travel-Accessories/c/30244">Travel Accessories</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Duffle-Bags/c/30285">Duffle Bags</a></li>
                                                        <li><a href="/bestprice/Luggage-%26-Apparel/Bags-%26-Luggage/Special-Order/c/30300">Special Order</a></li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h61/he6/9225910616094/Health%20and%20OTC.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Health &amp; OTC</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Balms-%26-Sprays/c/20026">Balms &amp; Sprays</a><a className="mob-only" href="javascript:void(0);">Balms &amp; Sprays</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Energy-%26-Health/c/20022">Energy &amp; Health</a><a className="mob-only" href="javascript:void(0);">Energy &amp; Health</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Digestives-%26-Acidity-Relief/c/20023">Digestives &amp; Acidity Relief</a><a className="mob-only" href="javascript:void(0);">Digestives &amp; Acidity Relief</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Cotton-%26-Bandages/c/20024">Cotton &amp; Bandages</a><a className="mob-only" href="javascript:void(0);">Cotton &amp; Bandages</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Antiseptic/c/20027">Antiseptic</a><a className="mob-only" href="javascript:void(0);">Antiseptic</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Cough-%26-Cold/c/20025">Cough &amp; Cold</a><a className="mob-only" href="javascript:void(0);">Cough &amp; Cold</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Contraceptives/c/20028">Contraceptives</a><a className="mob-only" href="javascript:void(0);">Contraceptives</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Health-Equipments/c/20021">Health Equipments</a><a className="mob-only" href="javascript:void(0);">Health Equipments</a>
                                                    <ul></ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Health-%26-OTC/Other-OTC/c/20029">Other OTC</a><a className="mob-only" href="javascript:void(0);">Other OTC</a>
                                                    <ul></ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <a data-toggle="pill" href="javascript:void(0);"><img className="bp-menu-icon" data-src="https://www.bestprice.in/sys-master-images/h93/hdc/9225910353950/IT,%20Electronics%20and%20Appliances.svg" alt=">" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Kitchen &amp; Home Appliances</span></a>
                                            <ul className="category-level-1">
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Fan/c/20097">Fan</a><a className="mob-only" href="javascript:void(0);">Fan</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Fan/Exhaust-Fan/c/30378">Exhaust Fan</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Fan/Table-%26-Wall-Fan/c/30376">Table &amp; Wall Fan</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Fan/Pedestal-Fan/c/30377">Pedestal Fan</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Fan/Deco-Fan/c/30379">Deco Fan</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Fan/Ceiling-Fan/c/30010">Ceiling Fan</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Heating/c/20098">Heating</a><a className="mob-only" href="javascript:void(0);">Heating</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Heating/Heaters-%26-Blowers/c/30006">Heaters &amp; Blowers</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Heating/Geyser-%26-Immersion-Rods/c/30008">Geyser &amp; Immersion Rods</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Lighting/c/20100">Lighting</a><a className="mob-only" href="javascript:void(0);">Lighting</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Lighting/LED-Bulb/c/30002">LED Bulb</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Lighting/CFL/c/30387">CFL</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Lighting/Batten/c/30388">Batten</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/c/20102">Mixer, Juicer and Wet Grinders</a><a className="mob-only" href="javascript:void(0);">Mixer, Juicer and Wet Grinders</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/Mixer-Grinder/c/30392">Mixer Grinder</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/Juicer-Mixer-Grinder/c/30394">Juicer Mixer Grinder</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/Juicer/c/30393">Juicer</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/Wet-Grinder/c/30391">Wet Grinder</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Mixer%2C-Juicer-and-Wet-Grinders/Blender/c/30022">Blender</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Iron/c/20099">Iron</a><a className="mob-only" href="javascript:void(0);">Iron</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Iron/Steam-Iron/c/30009">Steam Iron</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Iron/Dry-Iron/c/30380">Dry Iron</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a className="desk-only" href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/c/20005">Kitchen Appliance</a><a className="mob-only" href="javascript:void(0);">Kitchen Appliance</a>
                                                    <ul>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Refrigerators/c/30021">Refrigerators</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Water-Purifier/c/30018">Water Purifier</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Induction-Cooktops-%26-Cooker/c/30023">Induction Cooktops &amp; Cooker</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Gas-Stove/c/30024">Gas Stove</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Other-Kitchen-Appliances/c/30019">Other Kitchen Appliances</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Toasters-%26-Sandwich-Maker/c/30016">Toasters &amp; Sandwich Maker</a></li>
                                                        <li><a href="/bestprice/Kitchen-%26-Home-Appliances/Kitchen-Appliance/Air-Fryers-%26-Kettles/c/30017">Air Fryers &amp; Kettles</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Privacy
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from '../Services/common';

const ProtectedRoute = memo(({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = commonServices.getLocalData(storageConstants.AUTH);
    if (!isAuthenticated) {
      navigate("/");
    } else if (isAuthenticated && location.pathname === "/") {
      navigate("/")
    }
  }, [location.pathname]);

  return (
    <>
      {Component}
    </>
  );
});

export default ProtectedRoute;

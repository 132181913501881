import React, { useState } from "react";
import "./input.scss";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import { Form, InputGroup } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';
import { RUPEE } from "../../../Constants/storageConstant";
import { preventPlusMinusInNumberInput } from "../../../Services/common";
const Input = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const { onClickIconPre, onClickIconSuff, isRequired, showLoaderIcon, ...updatedProps } =
    props;

  const render = () => {
    switch (props?.inputType) {
      case "password":
        return (
          <>
            <Form.Label aria-label="password">
              {props?.label ? props?.label : "Password"}
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                autoComplete="false"
                pattern={props?.pattern}
                placeholder={
                  props?.placeholder
                    ? props?.placeholder
                    : "Enter your Password"
                }
                aria-label="password"
                aria-describedby="basic-addon2"
                type={showPassword ? "text" : "password"}
                {...updatedProps}
              />
              <InputGroup.Text
                id="basic-addon2"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
                style={{ cursor: "pointer" }}
              >
                <IconResource type={showPassword ? "unlock" : "lock"} size={25} />
              </InputGroup.Text>
            </InputGroup>
          </>
        );
      case "icon":
        return (
          <>
            {props?.label ? (
              <Form.Label aria-label={props?.label}>{props?.label}</Form.Label>
            ) : (
              ""
            )}
            <InputGroup className="mb-3">
              {props?.prefixIcon ? (
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={onClickIconPre ?? null}
                >
                  <IconResource type={props?.prefixIcon} />
                </InputGroup.Text>
              ) : (
                ""
              )}
              <Form.Control
                placeholder={props?.placeholder}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                type="text"
                pattern={props?.pattern}
                {...updatedProps}
              />
              {props?.suffixIcon ? (
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={onClickIconSuff ?? null}
                >
                  <IconResource
                    type={showLoaderIcon ? "loader" : props?.suffixIcon}
                  />
                </InputGroup.Text>
              ) : (
                ""
              )}
            </InputGroup>
          </>
        );
      case "currency":
        return <>
          {props?.label ? (<Form.Label className={isRequired ? "required" : ""} aria-label={props?.label}>{props?.label}</Form.Label>) : ("")}
          <InputGroup className="mb-3">
            <CurrencyInput
              style={{ width: "100%" }}
              id={props.controlId}
              name={props.controlId}
              className="currency_input"
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              allowNegativeValue={props?.allowNegativeValue ?? false}
              decimalSeparator="."
              groupSeparator=","
              allowDecimals={props?.allowDecimals ?? false}
              decimalsLimit={props?.allowDecimals ? (props?.decimalsLimit ?? 2) : 0}
              decimalScale={props?.allowDecimals ? (props?.decimalScale ?? 2) : 0}
              step={props?.stepSize}
              defaultValue={props.defaultValue}
              value={props?.value ?? 0}
              onValueChange={!props?.onValueChange
                ? null
                : (val, _, values) => {
                  let value = val;

                  if (props?.allowDecimals) {
                    const condition = value.includes('.') && (value.length - 1) === value.indexOf('.');
                    if (condition) value += '00';
                  }

                  props?.onValueChange(value, _, values);
                }}
              onKeyDown={e => preventPlusMinusInNumberInput(e)}
              prefix={props.currencyPrefix ? RUPEE : null}
              intlConfig={props.currencyPrefix ? { locale: 'en-IN', currency: 'INR' } : null}
              {...props}
            />
          </InputGroup>
        </>

      default:
        return (
          <>
            {props?.label ? (
              <Form.Label aria-label={props?.label}>{props?.label}</Form.Label>
            ) : (
              ""
            )}
            <InputGroup className="">
              {props?.prefixIcon ? (
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={onClickIconPre ?? null}
                >
                  <IconResource
                    type={props?.prefixIcon}
                    color={"var(--light-text)"}
                  />
                </InputGroup.Text>
              ) : (
                ""
              )}
              <Form.Control
                placeholder={props?.placeholder}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                type="text"
                required={props?.required}
                pattern={props?.pattern}
                {...updatedProps}
              />
              {props?.suffixIcon ? (
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={onClickIconSuff ?? null}
                >
                  <IconResource
                    type={showLoaderIcon ? "loader" : props?.suffixIcon}
                    color={"var(--light-text)"}
                  />
                </InputGroup.Text>
              ) : (
                ""
              )}
            </InputGroup>
          </>
        );
    }
  };
  return <>{render()}</>;
};

export default Input;

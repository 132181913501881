export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/';
export const BASE_IMG_URL = BASE_URL.replace('/api', '/storage');
export const LOGIN = "auth/login";
export const CHECK_USER = "auth/checkUser";
export const SEND_OTP = "auth/getOTP";
export const VERIFY_OTP = "auth/verify";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const GET_PROFILE = "profile";
export const UPDATE_PROFILE = "profile/update";
export const CHANGE_PASSWORD = "profile/changePassword";
export const MASTER_DATA = "master-data";
export const SYSTEM_DATA = "system-data";
export const PRODUCTS = "product"
export const PRODUCT_DETAIL = "product"
export const HOME = "home";
export const BRAND = "brand/detail/";
export const WISHLIST = "wishlist";
export const ADD_TO_CART = "cart/add";
export const GET_CART_PRODUCT = "cart"
export const EMPTY_CART = "cart/remove";
export const GET_CHECKOUT_DETAILS = "cart/checkout";
export const PLACED_ORDER = "order/save";
export const REMOVE_CART = "cart/delete";
export const ORDER = "order";
export const ADDRESS = "profile/address";
export const ADD_ADDRESS = "profile/address";
export const UPDATE_ADDRESS = "profile/address"
export const UPDATE_PASSWORD = "auth/forgotPassword"
export const COUPON = "apply-coupon";
export const GET_COUPON = "coupon"
import React from 'react'
import Lottie from 'react-lottie-player'

const LottieAnimation = ({ animation, width, height, props }) => {
    return (
        <div>
            <Lottie
                animationData={animation}
                play
                style={{ width: width, height: height }}
                {...props}
            />

        </div>
    )
}

export default LottieAnimation
import { memo } from 'react';
import empyCart from "../../Assets/img/empyCart.png";
import Text from '../_widgets/Text/Text';
import Button from '../_widgets/Button/Button';
import { useNavigate } from 'react-router-dom';

const CartEmpty = memo(() => {
    let navigate = useNavigate()
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-cart py-5">
                <img
                    src={empyCart}
                    className="img-fluid"
                    style={{ width: "150px" }}
                />
                <Text
                    className="mt-2 fw-bolder"
                    type="h4"
                    text="Your cart is empty"
                />
                <Text
                    className="mt-2"
                    type="h6"
                    text="You can search and browse through categories and <br> our recomendations and add product here"
                />
                <span className='my-2 mt-4'>
                    <Button
                        buttonType="primary"
                        onClick={(e) => navigate("/products")}
                        text="Start Shopping" />
                </span>
            </div>
        </>
    );
});

export default CartEmpty;

export const maskText = (text = '', length) => { // length = length to exclude the text from last, ex: ******1234

    if (text && length) {

        const maskedPart = '*'.repeat(length);  // Create the masked part
        const visiblePart = text.slice(length);

        if (length === text?.length) {
            return maskedPart
        }
        else {
            return maskedPart + visiblePart;
        }
    }
    return text;  // If text is empty or length is not valid, return the original text
};

import React from "react";
import "./loader.scss";
import SyncLoader from "react-spinners/SyncLoader";

const FullScreenLoader = ({ isLoading = true }) => {
  const override = {
    display: "block",
    borderColor: "var(--primary)",
  };

  return (
    <>
      {isLoading ? (
        <div className="full_screen_loader">
          <SyncLoader
            color={"var(--primary)"}
            cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FullScreenLoader;
